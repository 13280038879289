import React, { Fragment, useState } from 'react';
import Page from '~/components/Page';
// import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Row, Col } from 'reactstrap';

function SiteExterno(props) {
  const [loadingIframe, setLoadingIframe] = useState(true);

  const userCpf = localStorage.getItem('icaseUserCpf');

  let urlExterna = props?.match?.params?.urlExterna;

  if (urlExterna?.includes('{userCpf}'))
    urlExterna = urlExterna.replace('{userCpf}', userCpf);

  return (
    <Fragment>
      <Page loadPage={loadingIframe}>
        <Row>
          <Col xs="12">
            {/* <Card className="p-2">
              <CardHeader>
                <h5 className="title">Site Externo</h5>
                <p className="category">Exibição de site externo</p>
              </CardHeader>
              <CardBody> */}
                <iframe
                  title='Exibição de site externo'
                  style={{
                    width: '100%',
                    height: 'calc(100vh - 120px)',
                    border: 'none'
                  }}
                  onLoad={() => setLoadingIframe(false)}
                  src={decodeURIComponent(urlExterna)}
                  loading="lazy"></iframe>
              {/* </CardBody>
            </Card> */}
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default SiteExterno;
