import React, { Fragment, useState } from 'react';

import { Row, Col } from 'reactstrap';
import { Form, Input, Select, Badge, Tag, Button, Space } from 'antd';
import { SendOutlined } from '@ant-design/icons';

const { TextArea } = Input;

export default function AtendimentoPerdido({
  cardDetails,
  onClose,
  onSubmit,
  isLoading,
}) {
  const [form] = Form.useForm();

  const [reason, setReason] = useState(null);
  const [justification, setJustification] = useState(null);

  const reasonList = [
    { label: 'Já comprou imóvel', value: 1 },
    { label: 'Tentativas de contato sem sucesso', value: 2 },
    { label: 'Não aprovou financiamento', value: 3 },
    { label: 'Contato inexistente', value: 4 },
    { label: 'Apenas pesquisando', value: 5 },
    { label: 'Não gostou da localização', value: 6 },
    { label: 'Não gostou do projeto', value: 7 },
    { label: 'Achou caro', value: 8 },
  ];

  const handleChangeOption = (value) => {
    setReason(value);
  };

  const handleSubmit = () => {
    if (onSubmit && cardDetails?.id) {
      let payload = {
        atendimentoId: String(cardDetails?.id),
        status: 3,
        situacao: 3,
        motivo: reason,
      };

      if (justification) {
        payload.justificativa = justification;
      }

      onSubmit(payload);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const renderReasonList = reasonList?.map((reason, index) => (
    <Select.Option key={index} value={reason.value}>
      {reason.label}
    </Select.Option>
  ));

  return (
    <Fragment>
      <Form form={form}>
        <Row className="mb-4">
          <Col>
            <Badge
              status="error"
              text={
                <span>
                  Atualizar status de{' '}
                  <strong>{cardDetails?.cliente?.nome || ''}</strong> para{' '}
                  <Tag bordered={"false"} color="error">
                    PERDIDO
                  </Tag>
                </span>
              }
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm="12">
            <p className="heading-small text-muted mb-2">
              <small>
                Selecione um motivo <strong style={{ color: 'red' }}>*</strong>
              </small>
            </p>
            <Select
              placeholder="Escolha um motivo para o atendimento perdido"
              onChange={handleChangeOption}
              style={{
                width: '100%',
              }}>
              {renderReasonList}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p className="heading-small text-muted mb-2">
              <small>Justificativa (opcional)</small>
            </p>
            <TextArea
              rows={2}
              placeholder="Descreva aqui o motivo"
              onChange={(e) => setJustification(e.target.value)}
              showCount
            />
          </Col>
        </Row>
        <Row xs="4" className="mt-4">
          <Col
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            <Space>
              <Button
                loading={isLoading}
                type="primary"
                disabled={reason === null || isLoading}
                onClick={handleSubmit}>
                <SendOutlined />
                Enviar
              </Button>
              <Button disabled={isLoading} basic onClick={handleClose}>
                Fechar
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
}

