import styled from 'styled-components';
import {Image} from 'antd';

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  margin-bottom: 5px;
`;

export const GridWrapper = styled(Image.PreviewGroup)``;
