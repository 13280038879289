import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Select, Switch } from 'antd';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Icon } from 'semantic-ui-react';

import Notify from '~/shared/Notify';
import Button from '~/shared/Button';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import Page from '~/components/Page';
import EmptyBox from '~/components/EmptyBox';
import useUsuario from '~/actions/Corretor/useUsuario';
import useEmpresa from '~/actions/Empresa/useEmpresa';
import ModalConfirm from '~/components/ModalConfirm';
import HeaderFiltros from '~/components/HeaderFiltro';
import CardUSer from '~/components/CardUser';

import history from '~/services/history';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import url from '~/infra/urls/index';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';

const { Option } = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

export function navToNovo(identificador) {
  history.push({
    pathname: `/app/corretor/manutencao/${MODO.CADASTRAR}`,
    state: { identificador },
  });
}

function ConsultaMaster() {
  const [actions, setActions] = useState([]);
  const [loadCards, setloadCards] = useState(true);
  const [filtro, setFiltro] = useState('');
  const [filiaisId, setFiliaisId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState(null);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [userId, setUserId] = useState();
  const [modalIcon, setModalIcon] = useState('warning sign');
  const [isDelete, setIsDelete] = useState(false);
  const [remapEmpresas, setRemapEmpresas] = useState([]);
  const [exibirInativos, setExibirInativos] = useState(false);

  const { data: dataEmpresas } = useEmpresa(1, 9999, null, null);
  const { isLoading, data, onLoad } = useUsuario(filtro, filiaisId);

  const { identificador } = useParams();

  const [form] = Form.useForm();

  const empresaId = localStorage.getItem('icaseEmpresaId');
  const isMatriz = !!!JSON.parse(localStorage.getItem('latitudeCompanyData')).matrizId;

  useEffect(() => {
    setloadCards(false);

    const { actions } = getActionsMenus(identificador);

    setActions(actions || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, identificador]);

  useEffect(() => {
    if (isMatriz) {
      const remap = [];
      const index = dataEmpresas.findIndex((item) => item.id === empresaId);

      if (index > -1) {
        if (dataEmpresas[index]?.filiais !== null) {
          remap.push({
            id: dataEmpresas[index].id,
            nome: dataEmpresas[index].nome,
          });

          dataEmpresas[index].filiais.forEach((child) => {
            remap.push({
              id: child.id,
              nome: child.nome,
            });
          });

          setRemapEmpresas(remap);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEmpresas]);

  function handleOpenModal(corretor) {
    setUserId(corretor.usuarioId);
    setIsDelete(!corretor.excluido);
    setModalIcon(corretor.excluido === true ? 'check circle' : 'warning sign');
    setModalMessage(
      corretor.excluido === true
        ? 'Deseja realmente ativar este usuário?'
        : 'Deseja realmente inativar este usuário?',
    );
    setModalType(corretor.excluido === true ? 'update' : 'delete');
    setOpenModalConfirm(true);
  }

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  function onFiltros() {
    setloadCards(true);

    const { filtro, empresaId, exibirInativos } = form.getFieldsValue();

    setFiltro(filtro);
    setFiliaisId(empresaId);
    setExibirInativos(exibirInativos);

    setloadCards(false);
  }

  async function handleConfirmDelete() {
    if (userId) {
      setLoading(true);
      await api
        .patch(url.CORRETOR.INATIVAR(userId))
        .then((res) => {
          setLoading(false);
          onLoad();
          setOpenModalConfirm(false);
          Notify('success', '', 'Usuário inativado com sucesso!');
        })
        .catch((err) => {
          const { error } = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
          else
            Notify(
              'error',
              pt.comum.atencao,
              'Ocorreu um erro ao inativar o usuário!',
            );

          setLoading(false);
          setOpenModalConfirm(false);
        });
    }
  }

  async function handleConfirmEnabled() {
    if (userId) {
      setLoading(true);
      await api
        .patch(url.CORRETOR.ATIVAR(userId))
        .then((res) => {
          setLoading(false);
          onLoad();
          setOpenModalConfirm(false);
          Notify('success', '', 'Usuário ativado com sucesso!');
        })
        .catch((err) => {
          setLoading(false);
          setOpenModalConfirm(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao ativar o usuário!',
          );
        });
    }
  }

  const incluir = !!actions.find(
    (action) => action.id === 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
  );

  const isEmpty = !isLoading && !loadCards && data.length === 0;

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader className="pl-2">
                <h5 className="title">Controle de Usuário</h5>
                <p className="category">Manutenção Usuário</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" md="4"></Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form form={form} scrollToFirstError>
                        <Row>
                          {isMatriz && (
                            <Col xs="12" md="5">
                              <p className="heading-small text-muted mb-2">
                                <small>Empresa</small>
                              </p>
                              <Form.Item
                                className="my-2"
                                name="empresaId">
                                <Select
                                  placeholder=" Empresa"
                                  mode='multiple'
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                      .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                                  }
                                  allowClear>
                                  {remapEmpresas.map((item) => (
                                    <Option key={item.id}>{item.nome}</Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          )}
                          <Col xs="12" md="4">
                            <p className="heading-small text-muted mb-2">
                              <small>Filtro</small>
                            </p>
                            <Form.Item className="my-2" name="filtro">
                              <Input placeholder="Nome do usuário, email, CPF/CNPJ, CRECI ou telefone" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="2">
                            <p className="heading-small text-muted mb-2">
                              <small>&nbsp;</small>
                            </p>
                            <Button
                              className="mt-2"
                              color="green"
                              size="tiny"
                              icon
                              labelPosition="left"
                              fluid
                              onClick={() => onFiltros()}>
                              <Icon name="filter" />
                              Filtrar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="2">
                    <p className="heading-small text-muted mb-0">
                      <small>Exibir Inativos ?</small>
                    </p>
                    <Form.Item valuePropName="checked" name="exibirInativos">
                      <Switch
                        loading={loadCards}
                        checked={exibirInativos}
                        checkedChildren="Sim"
                        unCheckedChildren="Não"
                        size="small"
                        onChange={(value) => setExibirInativos(value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="mt-2">
              <CardHeader>
                <h5 className="title mt-1">{`Resultados: (${data?.filter(u => !u?.excluido || exibirInativos)?.length})`}</h5>
                {incluir === true ? (
                  <HeaderFiltros
                    navToNovo={() => navToNovo(identificador)}
                    description="Novo Usuário"
                  />
                ) : null}
              </CardHeader>
              <CardBody>
                {loadCards || isLoading ? (
                  <Row className="mt-4">
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                  </Row>
                ) : null}
                {!isLoading && !loadCards && !isEmpty && (
                  <Row className="px-1">
                    {data?.filter(u => !u?.excluido || exibirInativos).map((corretor, index) => (
                      <Col xs="12" md="6" key={index} className="p-1">
                        <CardUSer
                          key={index}
                          user={corretor}
                          type="usuário"
                          showEdit
                          url={`/app/corretor/manutencao/${MODO.EDITAR}`}
                          identificador={identificador}
                          actions={actions}
                          showInactivate
                          functionInactivate={() => handleOpenModal(corretor)}
                        />
                      </Col>
                    ))}
                  </Row>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>

            <ModalConfirm
              modalType={modalType}
              isDelete={isDelete}
              icon={modalIcon}
              buttonColor={modalType === 'delete' ? 'red' : 'violet'}
              buttonIcon={modalType === 'delete' ? 'trash' : 'check'}
              buttonText="Confirmar"
              loading={loading}
              open={openModalConfirm}
              confirm={
                modalType === 'delete'
                  ? handleConfirmDelete
                  : handleConfirmEnabled
              }
              close={handleCloseModal}
              message={modalMessage}
              loadingText={
                modalType === 'delete' ? 'Inativando...' : 'Ativando...'
              }
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ConsultaMaster;
