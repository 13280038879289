import React, {Fragment} from 'react';
import {ConfigProvider} from 'antd';
import {Router, Route, Switch, Redirect, useRouteMatch} from 'react-router-dom';
import {BackTop} from 'antd';

import ptBR from 'antd/es/locale/pt_BR';
import 'semantic-ui-css/semantic.min.css';
import 'antd/dist/antd.css';
import '~/assets/css/now-ui-dashboard.css?v=1.5.0';
import GlobalStyle from './assets/css/global';
import '~/assets/css/style.css';

import history from '~/services/history';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';

import moment from 'moment';
import 'moment/locale/pt-br';
import {LocalStorageProvider} from '~/hooks/localstorage/localStorageContext';
import {PublicExportRoutes} from './routes/PublicExportRoutes';
import {Helmet} from 'react-helmet';

moment.locale('pt-br');

const App = () => {
  const style = {
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 4,
    backgroundColor: '#1d1f8c',
    color: '#FFF',
    textAlign: 'center',
    fontSize: 14,
  };

  return (
    <Fragment>
      <Helmet>
        <meta property="og:title" content="Latitude Tech" />
        <meta property="og:url" content="https://www.latitude.com.br/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Gestão imobiliária com experiência, credibilidade e segurança!"
        />
        <meta
          property="og:image"
          content="https://www.latitude.com.br/locare_seo.png"
        />
        <title>Latitude Tech</title>
      </Helmet>
      <GlobalStyle />
      <ConfigProvider locale={ptBR}>
        <LocalStorageProvider prefix={'icase'}>
          <Router history={history}>
            <MainRoutes />
          </Router>
        </LocalStorageProvider>
      </ConfigProvider>
      <BackTop>
        <div style={style}>
          <i className="ri-space-ship-fill" style={{fontSize: '24px'}}></i>
        </div>
      </BackTop>
    </Fragment>
  );
};

const MainRoutes = () => {
  const isPublicExport = useRouteMatch('/public-export');

  const authentication = () => {
    if (localStorage.getItem('icaseUser') && !isPublicExport) {
      return <Redirect to="/app" />;
    }

    if (isPublicExport) {
      return <Redirect to="/public-export" />;
    }

    return <PublicRoutes />;
  };

  return (
    <Switch>
      <Route path="/app" component={PrivateRoutes} />
      <Route path="/public-export" component={PublicExportRoutes} />
      <Route path="" render={authentication} />
    </Switch>
  );
};

export default App;
