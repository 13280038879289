import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;

  width: 100%;
  height: 500px;

  overflow-x: auto;
  overflow-y: hidden;
`;

