import React, {useEffect} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {
  HighlightContainer,
  Image,
  ImageContainer,
  RemoveImageIcon,
} from './styles';
import {DeleteFilled} from '@ant-design/icons';

const ImageCard = ({
  src,
  title,
  id,
  index,
  moveImage,
  onDeleteImage,
  onDrag,
  showOrderIndicator,
}) => {
  const ref = React.useRef(null);

  const handleRemoveImage = () => {
    if (onDeleteImage) onDeleteImage();
  };

  const [, drop] = useDrop({
    accept: 'image',
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (
        dragIndex < hoverIndex &&
        hoverClientY < hoverMiddleY &&
        hoverClientX < hoverMiddleX
      ) {
        return;
      }

      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY &&
        hoverClientX > hoverMiddleX
      ) {
        return;
      }

      if (moveImage) moveImage(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{isDragging}, drag] = useDrag({
    type: 'image',
    item: () => {
      return {id, index};
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  let opacity = isDragging ? 0.5 : 1;

  drag(drop(ref));

  useEffect(() => {
    onDrag && onDrag(isDragging);
  }, [isDragging, onDrag]);

  const renderIfIsHighlight = index === 0 && (
    <HighlightContainer>
      <p>Foto de capa</p>
    </HighlightContainer>
  );

  if (showOrderIndicator) {
    opacity = 0.5;
  }

  return (
    <ImageContainer ref={ref} showOrderIndicator={showOrderIndicator}>
      <RemoveImageIcon className="remove-button" onClick={handleRemoveImage}>
        <DeleteFilled color="white" style={{color: 'red'}} />
      </RemoveImageIcon>
      <p className="image-order-indicator">{index + 1}</p>
      <Image
        style={{opacity}}
        src={src}
        alt={title}
        width={200}
        height={'100%'}
      />
      {renderIfIsHighlight}
    </ImageContainer>
  );
};

export default ImageCard;

