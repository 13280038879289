import React from 'react';
import {Grid, GridWrapper} from './styles';

export default function ImageGrid({children}) {
  return (
    <Grid className="image-grid">
      <GridWrapper>{children}</GridWrapper>
    </Grid>
  );
}

