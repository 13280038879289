import React, {Fragment, useState, useEffect, useCallback} from 'react';

import {useParams} from 'react-router-dom';
import {Form, Select, Input, DatePicker, Switch, Image, Checkbox} from 'antd';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  HomeOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {Icon, Transition} from 'semantic-ui-react';
import {mask} from 'remask';
import {decryptAll} from '~/infra/resources/pattern/cripto';

import Page from '~/components/Page';
import HeaderFiltros from '~/components/HeaderFiltro';
import EmptyBox from '~/components/EmptyBox';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import ModalRelatorioAtendimento from './components/ModalRelatorioAtendimento';
import ModalGenerico from '~/components/ModalGenerico';
import ModalConfirm from '~/components/ModalConfirm';
import ModalAtribuirLead from './components/ModalAtribuirLead';
import RegistroAtendimento from '../Manutencao/Components/RegistroAtendimento';
import AgendarVisita from '../Manutencao/Components/AgendarVisita';
import Proposta from '../Manutencao/Components/Proposta';
import CopyPhoneButton from './components/CopyPhoneButton';

import useAtendimentos from '~/actions/Atendimento/useAtendimentos';
import usePlantao from '~/actions/Plantoes/usePlantoes';
import useConfigSistema from '~/actions/Empresa/useConfigSistema';
import useTipoAtendimento from '~/actions/TipoAtendimento/useTipoAtendimento';

import statusAtendimento from '~/infra/resources/strings/enums/statusAtendimento';
import enumTipoDataAtendimento from '~/infra/resources/strings/enums/tipoDataAtendimento';
import enumOperacaoImobiliaria from '~/infra/resources/strings/enums/operacaoImobiliaria';
import history from '~/services/history';
import Button from '~/shared/Button';
import * as configConst from '~/infra/config/configSistemaConst';
import {FilterCache} from '~/shared/filterCache';
import pattern from '~/infra/resources/pattern/mask';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';
import formatCurrency from '~/shared/formatCurrency';
import imgImovel from '~/assets/img/sala.jpg';
import api from '~/services/api';
import url from '~/infra/urls';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';

import './index.css';

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import AtendimentoPerdido from '../Manutencao/Components/AtendimentoPerdido';
import useGrupoCorretores from '~/actions/DropDown/useGrupoCorretores';
import useTipoMidiaDropdown from '~/actions/DropDown/useTipoMidiaDropdown';
import useCorretorDropdown from '~/actions/DropDown/useCorretorDropdown';
import LatitudeBoard from '~/components/LatitudeBoard';

moment.locale('pt-BR');

const {Option} = Select;
const dateFormat = 'DD/MM/YYYY';
const {RangePicker} = DatePicker;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

// let hasManager = null;
let eventBus = undefined;
let thermometerColor;
let nameOfIcon;
let nameOfStatus = 'Normal';
let colorOfStatus = '#1E8F4B';
let textoAviso = '';
let structuredProfiles = [];

export function navToNovo(identificador, empresaId) {
  history.push({
    pathname: `/app/atendimentos/manutencao/${MODO.CADASTRAR}`,
    state: {identificador, empresaId},
  });
}

export function rowEdit(id, identificador, empresaId, withUrl) {
  const queryParams = new URLSearchParams({id, identificador, empresaId});
  const url = `/app/atendimentos/manutencao/editar?${queryParams.toString()}`;

  if (withUrl) return url;

  window.open(url, '_self');
}

const LaneHeaderModified = (props) => {
  let {showSelectedAllCheckbox} = props;

  return (
    <Fragment>
      <span className="line-header-first-title">{props.title}</span>
      <span className="line-header-second-title">{`Resultados: ${
        props?.total || 0
      }`}</span>
      {showSelectedAllCheckbox && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingInline: 10,
            marginTop: 5,
          }}>
          <Checkbox
            checked={props.checked}
            onChange={(e) => {
              props.handleSelectAll({
                id: props.id,
                checked: e.target.checked,
              });
            }}>
            <span>Selecionar todos</span>
          </Checkbox>
        </div>
      )}
      {props.cards.length === 0 && (
        <div
          style={{
            marginTop: '12px',
          }}>
          <p
            style={{
              fontSize: '12px',
              whiteSpace: 'normal',
              border: '1px dashed gray',
              borderRadius: '12px',
              paddingBlock: '12px',
              paddingInline: '4px',
              marginRight: '6px',
            }}>
            Arraste o lead aqui para adicioná-lo à coluna
          </p>
        </div>
      )}
    </Fragment>
  );
};

const CardAtendimento = ({
  data,
  disableAssignBroker,
  identificador,
  empresaId,
  onChangeChecked,
  checked,
  selectedLeads,
  integracaoComOnePlus,
  setOpenModalOnePlus,
  setSelectedCard,
}) => {
  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const isGerente = roles.some((x) => x.toUpperCase() === 'GERENTE');
  const isDiretor = roles.some(
    (x) => x.toUpperCase() === 'MASTER' || x.toUpperCase() === 'DIRETOR' || x.toUpperCase() === 'ADMMATRIZ',
  );

  switch (data?.termometro) {
    case 1:
      thermometerColor = '#10B4C9';
      nameOfIcon = 'snowflake';
      break;

    case 2:
      thermometerColor = '#1E8F4B';
      nameOfIcon = 'thermometer quarter';
      break;

    case 3:
      thermometerColor = '#C9B838';
      nameOfIcon = 'theme';
      break;

    case 4:
      thermometerColor = '#CA257B';
      nameOfIcon = 'thermometer full';
      break;

    case 5:
      thermometerColor = '#E01D20';
      nameOfIcon = 'fire';
      break;

    default:
      thermometerColor = '#FFF';
      nameOfIcon = 'snowflake';
      break;
  }

  switch (data?.validadeAtendimento) {
    case 1:
      nameOfStatus = 'Normal';
      colorOfStatus = '#1E8F4B';
      break;

    case 2:
      nameOfStatus = 'Vencendo no dia';
      colorOfStatus = '#10B4C9';
      break;

    case 3:
      nameOfStatus = 'Pendente de Retorno';
      colorOfStatus = '#C9B838';
      break;

    case 4:
      nameOfStatus = 'Irregular';
      colorOfStatus = '#E01D20';
      break;

    default:
      nameOfStatus = 'Concluído';
      colorOfStatus = '#1E8F4B';
  }

  const formatPhoneNumber = (numero, pattern) => {
    if (!numero) {
      return 'Sem telefone';
    }
    return `+${55} ${mask(numero, [pattern])}`;
  };

  const removePhoneSymbols = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, '');
  };

  const handleOpenWhatsApp = (phoneNumber) => {
    if (selectedLeads.length > 0) {
      return;
    }
    const formattedPhoneNumber = removePhoneSymbols(phoneNumber);
    const whatsappLink = `http://wa.me/${formattedPhoneNumber}`;

    window.open(whatsappLink, '_blank');
  };

  const formattedPhoneNumber =
    formatPhoneNumber(data?.clienteTelefone, pattern.celular) || 'Sem telefone';

  const handleOpenDetails = (withUrl) => {
    if (selectedLeads.length > 0) {
      return;
    }

    if (integracaoComOnePlus && data?.laneId === 'em-proposta') {
      return;
    }
    return rowEdit(data?.id, identificador, empresaId, withUrl);
  };

  const handleCheckCard = () => {
    if (onChangeChecked) {
      onChangeChecked(data?.id);
    }
  };

  const handlerEnviarOnePlus = () => {
    setSelectedCard(data);
    setOpenModalOnePlus(true);
  };

  // function sortByDate(a, b) {
  //   return new Date(b.dataCriacao) - new Date(a.dataCriacao);
  // }

  // const obterDataUltimoRelacionamento = (data) => {
  //   if (!data.eventos || data.eventos.length === 0)
  //     return '';

  //   return data.eventos.sort(sortByDate)[0].dataCriacao;
  // }

  const hasPermissionToAssignment =
    (isGerente || isDiretor) && !disableAssignBroker(data);

  const handleCardClick = (event) => {
    if (
      event.target.classList.contains('ignoreClick') ||
      event.target.classList.contains('ant-checkbox-input')
    )
      return;

    if (
      event.target.parentNode.classList.contains('ignoreClick') ||
      event.target.parentNode.classList.contains('ant-checkbox-input')
    )
      return;

    if (
      (isGerente || isDiretor) &&
      integracaoComOnePlus &&
      data?.laneId === 'em-proposta'
    ) {
      handlerEnviarOnePlus();
    } else {
      handleOpenDetails(false);
    }
  };
  return (
    <div onClick={handleCardClick}>
      <Card
        className="container-card"
        key={data?.id}
        style={{
          cursor: `${data?.draggable ? 'pointer' : 'not-allowed'}`,
        }}>
        <CardBody className="card-content p-2">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <div
              style={{
                marginBottom: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              {hasPermissionToAssignment && (
                <Checkbox
                  className="ignoreClick"
                  checked={checked}
                  onChange={() => handleCheckCard()}
                />
              )}
              <a
                style={{height: '20px', width: '100%', color: 'transparent'}}
                href={handleOpenDetails(true)}>
                Anchor
              </a>
              <a href={handleOpenDetails(true)}>
                <div
                  className="card-icon-thermometer"
                  style={{background: thermometerColor}}>
                  <Icon name={nameOfIcon} size="small" />
                </div>
              </a>
            </div>
            <a href={handleOpenDetails(true)}>
              <div
                style={{display: 'flex', justifyContent: 'space-between'}}
                onClick={handleOpenDetails}>
                <span className="card-content-title-text">
                  {data?.clienteNome || ''}
                </span>
              </div>
              {integracaoComOnePlus && data?.laneId === 'em-proposta' ? (
                <span className="card-content-simple-text">
                  <b style={{color: colorOfStatus}}>Proposta Enviada</b>
                </span>
              ) : (
                <span className="card-content-simple-text">
                  Status: <b style={{color: colorOfStatus}}>{nameOfStatus}</b>
                </span>
              )}
            </a>
          </div>
          <span className="card-content-simple-text ignoreClick">
            Tel:{' '}
            <b
              className="ignoreClick"
              style={{marginRight: 5, cursor: 'pointer'}}
              onClick={() => handleOpenWhatsApp(formattedPhoneNumber)}>
              {formattedPhoneNumber}
            </b>
            <CopyPhoneButton
              className="ignoreClick"
              phone={formattedPhoneNumber}
            />
          </span>
          <a href={handleOpenDetails(true)}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <span className="card-content-simple-text">
                Email: <b>{data?.clienteEmail}</b>
              </span>
              {(isGerente || isDiretor) && (
                <span className="card-content-simple-text">
                  Corretor: <b>{data?.corretorNome}</b>
                </span>
              )}
              {isDiretor && (
                <span className="card-content-simple-text">
                  Equipe: <b>{data?.grupoCorretoresNome || ' Sem equipe'}</b>
                </span>
              )}
              <span className="card-content-simple-text">
                Última interação:{' '}
                <b>
                  {!!data?.dataUltimaInteracao
                    ? moment(data?.dataUltimaInteracao).format('L')
                    : moment(data?.dataAtualizacao).format('L')}
                </b>
              </span>
              <span className="card-content-simple-text">
                Próximo contato:{' '}
                <b>
                  {!!data?.dataProximoContato
                    ? moment(data?.dataProximoContato).format('L')
                    : 'Sem data'}
                </b>
              </span>
              {!data?.imovelId && (
                <span className="card-content-simple-text mb-0">
                  <HomeOutlined style={{color: '#414141', marginRight: 5}} />
                  Nenhum imóvel selecionado
                </span>
              )}
            </div>

            {data?.imovelId && (
              <Row className="pl-2">
                <Col xxl="6">
                  {!!data?.imovelImagem ? (
                    <Image
                      style={{
                        minWidth: 100,
                        maxWidth: 100,
                        minHeight: 70,
                        maxHeight: 70,
                      }}
                      preview={false}
                      src={data?.imovelImagem}
                    />
                  ) : (
                    <Image
                      style={{
                        minWidth: 100,
                        maxWidth: 100,
                        minHeight: 70,
                        maxHeight: 70,
                      }}
                      preview={false}
                      src={imgImovel}
                    />
                  )}
                </Col>
                <Col xxl="6">
                  <div className="content-imovel">
                    <span>{data?.imovelDescricao}</span>
                    <span>
                      <b>{`R$ ${formatCurrency(data?.imovelValor || 0)}`}</b>
                    </span>
                    <span>
                      Código: <b>{data?.imovelCodigo}</b>
                    </span>
                  </div>
                </Col>
              </Row>
            )}
          </a>
        </CardBody>
      </Card>
    </div>
  );
};

function ConsultaAtendimentos() {
  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const isCorretor = roles.some((x) => x.toUpperCase() === 'CORRETOR');
  const isGerente = roles.some((x) => x.toUpperCase() === 'GERENTE');
  const isDiretor = roles.some(
    (x) => x.toUpperCase() === 'MASTER' || x.toUpperCase() === 'DIRETOR' || x.toUpperCase() === 'ADMMATRIZ',
  );

  const encryptedProfiles = localStorage.getItem('latitudeProfiles');
  if (encryptedProfiles) {
    const decryptProfiles = decryptAll(encryptedProfiles);
    structuredProfiles = JSON.parse(decryptProfiles);
  }
  const administrador =
    roles.some((x) => x.toUpperCase() === 'MASTER') ||
    structuredProfiles.some(
      (profile) => profile?.perfil?.descricao.toUpperCase() === 'GERENTE',
    );

  const masterLeads = JSON.parse(localStorage.getItem('icaseMasterLeads'));
  const usuarioId = localStorage.getItem('icaseUsuarioId');
  const userCpf = localStorage.getItem('icaseUserCpf');
  const {checkConfig} = useConfigSistema();
  const cacheFiltros = FilterCache.getFiltrosAtendimentos();

  // eslint-disable-next-line no-unused-vars
  const [current, setCurrent] = useState(null);
  const [filterParams, setFilterParams] = useState({});
  const [fakeDateRange, setFakeDateRange] = useState([]);
  const [rangeDisabled, setRangeDisabled] = useState(false);
  const [corretorId, setCorretorId] = useState(cacheFiltros?.corretorId);
  const [loadCards] = useState(false);
  const [empresaId, setEmpresaId] = useState('');
  const [modalRelatorioOpen, setModalRelatorioOpen] = useState(false);
  const [modalAtribuirLeadOpen, setModalAtribuirLeadOpen] = useState(false);
  const [selectedCorretor, setSelectedCorretor] = useState(null);
  const [maisFiltros, setMaisFiltros] = useState(false);
  const [hasSelectCorretor, setHasSelectCorretor] = useState(false);
  const [actions, setActions] = useState([]);
  const [firstFilter, setFirstFilter] = useState(true);
  const [selectedCard, setSelectedCard] = useState();
  const [openModalAviso, setOpenModalAviso] = useState(false);
  const [openModalAtendimento, setOpenModalAtendimento] = useState(false);
  const [openModalVisita, setOpenModalVisita] = useState(false);
  const [openModalProposta, setOpenModalProposta] = useState(false);
  const [openModalOnePlus, setOpenModalOnePlus] = useState(false);
  const [openModalPerdido, setOpenModalPerdido] = useState(false);
  const [isChangeSituacaoAtendimento, setIsChangeSituacaoAtendimento] =
    useState(false);

  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(2);
  const {data: dataTipoAtendimento} = useTipoAtendimento();
  const {data: corretores} = useCorretorDropdown();
  const {data: plantoes} = usePlantao();

  const {data: dataGrupoCorretores} = useGrupoCorretores();

  const brokerGroupOptions = dataGrupoCorretores?.map((brokerGroup) => ({
    label: brokerGroup?.grupo,
    value: brokerGroup.id,
  }));

  const {isLoading, data, quantidade, onLoad, filtros} = useAtendimentos({
    firstFilter: true,
  });
  const [atendimentos, setAtendimentos] = useState({
    lanes: [],
  });
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [, setLaneIds] = useState(null);

  function extractLaneId(lanes) {
    let result = {};

    lanes.forEach((item) => {
      result[item.id] = 1;
    });

    return result;
  }

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.action === 'post') {
        // Aqui você pode fazer algo quando a página dentro do iframe executa um POST
        console.log('Um post ocorreu dentro do iframe!');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (data && data.lanes && atendimentos.lanes.length === 0) {
      const dataCopy = {lanes: data?.lanes};
      setAtendimentos(dataCopy);

      if (isFirstRender) {
        setIsFirstRender(false);

        const laneIdsObject = extractLaneId(data?.lanes);
        setLaneIds(laneIdsObject);
      }
    }

    if (!isFirstRender && data && data.lanes) {
      const updatedLanes = atendimentos.lanes.map((lane, i) => {
        if (lane.id === data?.lanes[0]?.id) {
          const currentCards = lane.cards || [];
          const newCards = data?.lanes[0]?.cards?.filter(
            (card) => !currentCards.some((c) => c.id === card.id),
          );

          if (newCards.length > 0 && currentCards.length < lane.total - 1) {
            return {
              ...lane,
              cards: [...currentCards, ...newCards],
            };
          }
        }

        return lane;
      });

      setAtendimentos((prevAtendimentos) => ({
        ...prevAtendimentos,
        lanes: updatedLanes,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const localEmpresaId = localStorage.getItem('icaseEmpresaId');
  const {identificador: parmIdentificador} = useParams();

  const [form] = Form.useForm();

  useEffect(() => {
    const {actions} = getActionsMenus(parmIdentificador);
    setActions(actions || []);

    if (localEmpresaId) setEmpresaId(localEmpresaId);

    if (corretores) {
      let todos = false;
      corretores.forEach((item) => {
        if (item.id === 0) todos = true;
      });

      if (todos === false) {
        corretores.unshift({
          id: 'TODOS',
          nome: 'TODOS',
        });
      }

      setHasSelectCorretor(corretores.length > 0);
    }

    if (firstFilter) {
      setFirstFilter(false);
      onFiltros();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corretores, localEmpresaId, parmIdentificador]);

  function controlFilter(value, control, number) {
    if (value === true || value === undefined) {
      control.push(number);
    } else {
      control.splice(number, 1);
    }
    return control;
  }

  function disableAssignBrokerFunction(currentData) {
    const tipoMidia = currentData?.tipoMidia;

    if (
      tipoMidia === 109 &&
      !checkConfig(configConst.PERMITE_TRANSF_CARTEIRA_PROPRIA)
    ) {
      return true;
    } else {
      return false;
    }
  }

  function onFiltros() {
    // Reseting selected items
    setSelectedLeads([]);
    setSelectedLanes([]);

    setCurrent(1);

    const {
      situacao,
      pesquisa,
      status,
      validade,
      tipoData = 1,
      plantaoId,
      tipoMidia,
      semCorretor,
      leadBase,
      atendimento,
      emVisita,
      comProposta,
      statusOcorrencia,
      tipoAtendimentoId,
      grupoCorretoresId,
      operacaoImobiliaria,
      identificador,
      campanha,
      formulario,
      pagina,
    } = form.getFieldsValue();

    let auxFunil = [];
    auxFunil = controlFilter(leadBase, auxFunil, 1);
    auxFunil = controlFilter(atendimento, auxFunil, 2);
    auxFunil = controlFilter(emVisita, auxFunil, 3);
    auxFunil = controlFilter(comProposta, auxFunil, 4);

    const params = {
      situacao:
        firstFilter && cacheFiltros?.situacao
          ? cacheFiltros?.situacao
          : situacao,
      pesquisa:
        firstFilter && cacheFiltros?.pesquisa
          ? cacheFiltros?.pesquisa
          : pesquisa,
      status:
        firstFilter && cacheFiltros?.status ? cacheFiltros?.status : status,
      validade:
        firstFilter && cacheFiltros?.validade
          ? cacheFiltros?.validade
          : validade,
      tipoData:
        firstFilter && cacheFiltros?.tipoData
          ? cacheFiltros?.tipoData
          : tipoData,
      plantaoId:
        firstFilter && cacheFiltros?.plantaoId
          ? cacheFiltros?.plantaoId
          : plantaoId,
      tipoMidia:
        firstFilter && cacheFiltros?.tipoMidia
          ? cacheFiltros?.tipoMidia
          : tipoMidia,
      corretorId: corretorId && corretorId !== 'TODOS' ? corretorId : undefined,
      semCorretor:
        firstFilter && cacheFiltros?.semCorretor
          ? cacheFiltros?.semCorretor
          : semCorretor,
      leadBase,
      atendimento,
      emVisita,
      comProposta,
      statusFunil:
        firstFilter && cacheFiltros?.statusFunil
          ? cacheFiltros?.statusFunil
          : auxFunil,
      statusOcorrencia:
        firstFilter && cacheFiltros?.statusOcorrencia
          ? cacheFiltros?.statusOcorrencia
          : statusOcorrencia,
      tipoAtendimentoId:
        firstFilter && cacheFiltros?.tipoAtendimentoId
          ? cacheFiltros?.tipoAtendimentoId
          : tipoAtendimentoId,
      grupoCorretoresId:
        firstFilter && cacheFiltros?.grupoCorretoresId
          ? cacheFiltros?.grupoCorretoresId
          : grupoCorretoresId,
      operacaoImobiliaria:
        firstFilter && cacheFiltros?.operacaoImobiliaria
          ? cacheFiltros?.operacaoImobiliaria
          : operacaoImobiliaria,
      identificador:
        firstFilter && cacheFiltros?.identificador
          ? cacheFiltros?.identificador
          : identificador,
      campanha:
        firstFilter && cacheFiltros?.campanha
          ? cacheFiltros?.campanha
          : campanha,
      formulario:
        firstFilter && cacheFiltros?.formulario
          ? cacheFiltros?.formulario
          : formulario,
      pagina:
        firstFilter && cacheFiltros?.pagina ? cacheFiltros?.pagina : pagina,
    };

    if (fakeDateRange && (fakeDateRange[0] || fakeDateRange[1]) && tipoData) {
      params.dataIni = fakeDateRange[0]?.format('YYYY/MM/DD');
      params.dataFim = fakeDateRange[1]?.format('YYYY/MM/DD');
    }

    setFilterParams(params);
    setIsFirstRender(true);
    setAtendimentos({
      lanes: [],
    });

    onLoad({params: {...params, isFirstRender: true}});
  }

  function onChangeCorretor(usuario) {
    setCorretorId(usuario);
  }

  function onChangeTipoData(value) {
    setRangeDisabled(!value);
  }

  function onChangeData(date, dateString) {
    setFakeDateRange(date);
  }

  const [isUpdating, setIsUpdating] = useState(false);

  const updateStatusToPerdido = (payload) => {
    setIsUpdating(true);
    api
      .put(url.ATENDIMENTO.ATUALIZAR_STATUS(), payload)
      .then(() => {
        setOpenModalPerdido(false);
      })
      .catch((err) => {
        eventBus.publish({type: 'UPDATE_LANES', lanes: data?.lanes});

        const {error} = err?.response?.data;
        if (error) {
          for (const item in error) {
            Notify('error', pt.comum.atencao, error[item]);
          }
        } else
          console.log(
            `Erro no request ${url.ATENDIMENTO.ATUALIZAR_STATUS()}: `,
            JSON.stringify(err),
          );
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  function closeModal() {
    if (isChangeSituacaoAtendimento || openModalAviso)
      eventBus.publish({type: 'UPDATE_LANES', lanes: data?.lanes});

    setIsChangeSituacaoAtendimento(false);
    setOpenModalAviso(false);
    setOpenModalAtendimento(false);
    setOpenModalVisita(false);
    setOpenModalProposta(false);
    setOpenModalOnePlus(false);
    setOpenModalPerdido(false);
  }

  function closeModalPerdido() {
    eventBus.publish({type: 'UPDATE_LANES', lanes: data?.lanes});
    setOpenModalPerdido(false);
  }

  function closeModalProposta() {
    eventBus.publish({type: 'UPDATE_LANES', lanes: data?.lanes});
    setOpenModalProposta(false);
    setSelectedCard(data);
  }

  function onSubmitProposta() {
    setOpenModalProposta(false);

    if (
      (isGerente || isDiretor) &&
      checkConfig(configConst.INTEGRAR_COM_ONEPLUS)
    )
      setOpenModalOnePlus(true);
  }

  function closeModalOnePlus() {
    setOpenModalOnePlus(false);
  }

  const isEmpty = !isLoading && !quantidade;

  const canManageAllServices = !!actions.find(
    (action) => action.id === '9ea7c131-4575-4d03-a469-256977cc9546',
  );

  const filtrosMaster =
    (administrador || masterLeads || canManageAllServices) && hasSelectCorretor;

  const incluir = !!actions.find(
    (action) => action.id === 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
  );

  const imprimir = !!actions.find(
    (action) => action.id === 'e17b0d5c-6453-44ef-b782-92ff6b5b191b',
  );

  const [selectedLeads, setSelectedLeads] = useState([]);

  /**
   * Função que verifica se o botão de selecionar todos deve ser exibido
   * Verifica se existem cards já selecionados para aquela lane.
   * @param {string} laneId - Id da lane
   * @returns {boolean} - Retorna se o botão deve ser exibido
   */
  const mustShowSelectAll = useCallback(
    (laneId) => {
      const laneCards =
        atendimentos.lanes?.find((atend) => atend.id === laneId)?.cards || [];
      let showButton = false;
      if (selectedLeads.length > 0) {
        showButton = laneCards.some((card) => selectedLeads.includes(card.id));
      }

      return showButton;
    },
    [selectedLeads, atendimentos.lanes],
  );

  const [selectedLanes, setSelectedLanes] = useState([]);

  const handleSelectAll = (laneId, checked) => {
    const laneCards =
      atendimentos.lanes?.find((atend) => atend.id === laneId)?.cards || [];

    if (checked) {
      setSelectedLanes((current) => [...current, laneId]);
      setSelectedLeads((currentList) => {
        // If exists repeated leads, keep only one
        const leads = [...currentList, ...laneCards.map((card) => card.id)];
        return leads.filter((lead, index) => leads.indexOf(lead) === index);
      });
    } else {
      // Remove laneId from selectedLanes
      setSelectedLanes((current) => current.filter((lane) => lane !== laneId));
      setSelectedLeads((currentList) => {
        return currentList.filter(
          (lead) => !laneCards.some((card) => card.id === lead),
        );
      });
    }
  };

  const handleChangeChecked = (leadId) => {
    setSelectedLeads((currentList) => {
      const alreadyOnList = currentList.find((item) => item === leadId);

      if (alreadyOnList !== undefined) {
        return currentList.filter((lead) => lead !== leadId);
      }

      return [...currentList, leadId];
    });
  };

  const checkAllSelected = useCallback(
    (laneId) => {
      const laneCards =
        atendimentos.lanes?.find((atend) => atend.id === laneId)?.cards || [];
      const isAllSelected = laneCards.every((card) =>
        selectedLeads.includes(card.id),
      );

      if (!isAllSelected) {
        setSelectedLanes((current) =>
          current.filter((lane) => lane !== laneId),
        );
      }

      return isAllSelected;
    },
    [selectedLeads, atendimentos.lanes],
  );

  const [isHandlingLeads, setIsHandlingLeads] = useState(false);
  const {data: tiposMidia} = useTipoMidiaDropdown();

  async function handleAtribuiCorretor(idCorretor) {
    let payload = {
      idCorretor,
      atendimentos: selectedLeads,
      lanes: [],
    };

    if (selectedLanes.length > 0) {
      payload = {
        ...payload,
        lanes: selectedLanes,
        filtros,
      };
    }

    setIsHandlingLeads(true);

    await api
      .post(url.ATENDIMENTO.ATRIBUI_CORRETOR(), payload)
      .then(async (res) => {
        if (res.status === 200) {
          setModalAtribuirLeadOpen(false);
          setSelectedLeads([]);
          setSelectedCorretor(null);
          Notify('success', 'Lead(s) atribuído(s) com sucesso.');

          setIsFirstRender(true);
          setAtendimentos({
            lanes: [],
          });
          await onLoad({
            params: {isFirstRender: true},
          });
        }
      })
      .catch((err) => {
        Notify('error', 'Houve um erro ao atribuir o(s) lead(s).');
      })
      .finally(() => {
        setIsHandlingLeads(false);
      });
  }

  const [cardDetails] = useState(null);

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h5 className="title">Controle de atendimentos</h5>
                    <p className="category">Manutenção de atendimentos</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'baseline',
                      color: '#1d1f8c',
                    }}>
                    {!maisFiltros && (
                      <div
                        onClick={() => setMaisFiltros(true)}
                        style={{cursor: 'pointer'}}>
                        <CaretDownOutlined
                          style={{fontSize: '20px', verticalAlign: 'top'}}
                        />
                        <span>Ver mais</span>
                      </div>
                    )}
                    {maisFiltros && (
                      <div
                        onClick={() => setMaisFiltros(false)}
                        style={{cursor: 'pointer'}}>
                        <CaretUpOutlined
                          style={{
                            fontSize: '20px',
                            verticalAlign: 'text-bottom',
                          }}
                        />
                        <span>Ver menos</span>
                      </div>
                    )}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Transition
                  visible={maisFiltros}
                  animation="scale"
                  duration={500}>
                  <Form
                    form={form}
                    initialValues={cacheFiltros}
                    scrollToFirstError>
                    <Row>
                      {filtrosMaster ? (
                        <Col xs="12" md="3">
                          <div className="my-2">
                            <Select
                              allowClear
                              defaultValue={cacheFiltros?.corretorId || 'TODOS'}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, '')
                                  .indexOf(
                                    input
                                      .toLowerCase()
                                      .normalize('NFD')
                                      .replace(/[\u0300-\u036f]/g, ''),
                                  ) >= 0
                              }
                              placeholder=" Atendente"
                              style={{width: '100%'}}
                              onChange={(user) => onChangeCorretor(user)}>
                              {corretores.map((item) => (
                                <Option
                                  key={item.id}
                                  disabled={item.isSeparator}>
                                  {item?.nome?.toString()}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs="12" md={filtrosMaster ? '4' : '5'}>
                        <Form.Item className="my-2" name="pesquisa">
                          <Input
                            prefix={<SearchOutlined />}
                            placeholder="Nome do Cliente/Corretor, Equipe, E-mail, CPF/CNPJ ou Telefone"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md={filtrosMaster ? '2' : '3'}>
                        <Form.Item className="my-2" name="identificador">
                          <Input placeholder=" Código Imóvel" type="number" />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md={filtrosMaster ? '3' : '4'}>
                        <Form.Item className="my-2" name="plantaoId">
                          <Select allowClear placeholder=" Plantão">
                            {plantoes.map((item) => (
                              <Option key={item.id}>{item.descricao}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="3">
                        <Form.Item className="my-2" name="situacao">
                          <Select allowClear placeholder=" Situação">
                            <Option value={1}>Ativo</Option>
                            <Option value={3}>Concluído</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="3">
                        <Form.Item className="my-2" name="tipoMidia">
                          <Select allowClear placeholder=" Mídia">
                            {tiposMidia.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.descricao}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="3">
                        <Form.Item className="my-2" name="status">
                          <Select allowClear placeholder=" Status do negócio">
                            {statusAtendimento.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="3">
                        <Form.Item className="my-2" name="tipoData">
                          <Select
                            allowClear
                            placeholder=" Data a ser avaliada"
                            defaultValue={1}
                            onChange={onChangeTipoData}>
                            {enumTipoDataAtendimento.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.nome}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="4">
                        <div className="py-2">
                          <RangePicker
                            disabled={rangeDisabled}
                            onChange={(date, dateString) =>
                              onChangeData(date, dateString)
                            }
                            locale={locale}
                            format={dateFormat}
                            style={{width: '100%'}}
                          />
                        </div>
                      </Col>
                      <Col xs="12" md="4">
                        <Form.Item className="my-2" name="validade">
                          <Select allowClear placeholder=" Validade">
                            <Option value={1}>Normal</Option>
                            <Option value={2}>Vencendo no dia</Option>
                            <Option value={3}>Pendente de Retorno</Option>
                            <Option value={4}>Irregular</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="4">
                        <Form.Item className="my-2" name="tipoAtendimentoId">
                          <Select allowClear placeholder=" Tipo de Cliente">
                            {dataTipoAtendimento.map((item) => (
                              <Option key={item.id}>{item.descricao}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      {checkConfig(configConst.CONTROLA_OCORRENCIAS) && (
                        <Col xs="12" md="4">
                          <Form.Item className="my-2" name="statusOcorrencia">
                            <Select
                              allowClear
                              placeholder=" Status da Ocorrência">
                              <Option value={0}>Não Enviado</Option>
                              <Option value={1}>Aguardando Conferência</Option>
                              <Option value={2}>Com Pendência</Option>
                              <Option value={3}>Contrato Fechado</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      )}
                      {isDiretor && (
                        <Col xs="12" md="3">
                          <Form.Item className="my-2" name="grupoCorretoresId">
                            <Select
                              allowClear
                              options={brokerGroupOptions}
                              placeholder="Equipe de Corretores"
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col xs="12" md="3">
                        <Form.Item className="my-2" name="operacaoImobiliaria">
                          <Select
                            allowClear
                            placeholder=" Operação Imobiliária">
                            {enumOperacaoImobiliaria.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.nome}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="2">
                        <Form.Item className="my-2" name="campanha">
                          <Input
                            placeholder=" Campanha"
                            allowClear
                            type="text"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="2">
                        <Form.Item className="my-2" name="formulario">
                          <Input
                            allowClear
                            placeholder=" Formulario"
                            type="text"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="2">
                        <Form.Item className="my-2" name="pagina">
                          <Input allowClear placeholder=" Pagina" type="text" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      {filtrosMaster && (
                        <Col xs="12" md="2">
                          <p className="heading-small text-muted mb-0">
                            <small>Sem Corretor?</small>
                          </p>
                          <Form.Item valuePropName="checked" name="semCorretor">
                            <Switch
                              loading={loadCards}
                              // checked={semCorretor}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                              size="small"
                            />
                          </Form.Item>
                        </Col>
                      )}
                      {filtrosMaster && (
                        <Col xs="12" md="2">
                          <p className="heading-small text-muted mb-0">
                            <small>Lead na base?</small>
                          </p>
                          <Form.Item valuePropName="checked" name="leadBase">
                            <Switch
                              defaultChecked
                              loading={loadCards}
                              // checked={leadBase}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                              size="small"
                            />
                          </Form.Item>
                        </Col>
                      )}
                      {filtrosMaster && (
                        <Col xs="12" md="2">
                          <p className="heading-small text-muted mb-0">
                            <small>Atendimento?</small>
                          </p>
                          <Form.Item valuePropName="checked" name="atendimento">
                            <Switch
                              defaultChecked
                              loading={loadCards}
                              // checked={atendimento}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                              size="small"
                            />
                          </Form.Item>
                        </Col>
                      )}
                      {filtrosMaster && (
                        <Col xs="12" md="2">
                          <p className="heading-small text-muted mb-0">
                            <small>Em visita?</small>
                          </p>
                          <Form.Item valuePropName="checked" name="emVisita">
                            <Switch
                              defaultChecked
                              loading={loadCards}
                              // checked={emVisita}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                              size="small"
                            />
                          </Form.Item>
                        </Col>
                      )}
                      {filtrosMaster && (
                        <Col xs="12" md="2">
                          <p className="heading-small text-muted mb-0">
                            <small>Com proposta?</small>
                          </p>
                          <Form.Item valuePropName="checked" name="comProposta">
                            <Switch
                              defaultChecked
                              loading={loadCards}
                              // checked={comProposta}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                              size="small"
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xs="12" md="3">
                        <Button
                          className="my-4"
                          size="medium"
                          icon
                          color="green"
                          labelPosition="left"
                          fluid
                          onClick={() => onFiltros()}>
                          <Icon name="filter" />
                          Filtrar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Transition>
              </CardBody>
            </Card>

            <Card className="mt-2">
              <CardHeader>
                <h5 className="title mt-1">{`Resultados: (${
                  quantidade || 0
                })`}</h5>
                <HeaderFiltros
                  navToNovo={() => navToNovo(parmIdentificador)}
                  description="Novo Atendimento"
                  action={incluir}>
                  {imprimir && (
                    <>
                      <Col xs="12" md="3">
                        <div className="pt-2">
                          <Button
                            className="my-1"
                            size="small"
                            icon
                            labelPosition="left"
                            fluid
                            onClick={() => setModalRelatorioOpen(true)}>
                            <Icon name="file outline" />
                            Relatório
                          </Button>
                        </div>
                      </Col>
                    </>
                  )}
                  {selectedLeads?.length > 0 && (
                    <Col xs="12" md="3">
                      <div className="pt-2">
                        <Button
                          className="my-1"
                          size="medium"
                          fluid
                          icon
                          color="green"
                          labelPosition="left"
                          basic
                          onClick={() => setModalAtribuirLeadOpen(true)}>
                          <Icon name="clipboard check" />
                          Atribuir corretor
                        </Button>
                      </div>
                    </Col>
                  )}
                </HeaderFiltros>
              </CardHeader>
              <CardBody>
                {(loadCards || isLoading) && (
                  <Row className="mt-4">
                    <Col xs="12" md="4">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                    <Col xs="12" md="4">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                    <Col xs="12" md="4">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                  </Row>
                )}
                {!isEmpty && !isLoading && !loadCards && (
                  <Fragment>
                    <LatitudeBoard
                      data={data}
                      components={{
                        Header: (props) => {
                          const showSelectedAllCheckbox = mustShowSelectAll(
                            props?.id,
                          );
                          return (
                            <LaneHeaderModified
                              {...props}
                              showSelectedAllCheckbox={showSelectedAllCheckbox}
                              checked={
                                selectedLanes.find(
                                  (item) => item === props?.id,
                                ) !== undefined && checkAllSelected(props?.id)
                              }
                              handleSelectAll={(callback) => {
                                handleSelectAll(props?.id, callback.checked);
                              }}
                            />
                          );
                        },
                        Card: (data) => (
                          <CardAtendimento
                            data={data}
                            disableAssignBroker={disableAssignBrokerFunction}
                            identificador={parmIdentificador}
                            empresaId={localEmpresaId}
                            onChangeChecked={handleChangeChecked}
                            selectedLeads={selectedLeads}
                            integracaoComOnePlus={checkConfig(
                              configConst.INTEGRAR_COM_ONEPLUS,
                            )}
                            setOpenModalOnePlus={setOpenModalOnePlus}
                            setSelectedCard={setSelectedCard}
                            checked={
                              selectedLeads.find(
                                (item) => item === data?.id,
                              ) !== undefined
                            }
                          />
                        ),
                      }}
                    />
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ModalAtribuirLead
          open={modalAtribuirLeadOpen}
          close={() => setModalAtribuirLeadOpen(false)}
          selectedEvent={(v) => setSelectedCorretor(v)}
          confirmEvent={handleAtribuiCorretor}
          listCorretores={corretores}
          isLoadingCorretores={false}
          isHandlingLeads={isHandlingLeads}
          formValues={{}}
          corretorId={selectedCorretor}
          showSecondConfirmation={selectedLanes.length > 0}
          textTitle={'Selecione um corretor para atribuir os leads:'}
        />

        <ModalRelatorioAtendimento
          open={modalRelatorioOpen}
          close={() => setModalRelatorioOpen(false)}
          empresaId={empresaId}
          isCorretor={isCorretor}
          corretorId={corretorId}
          usuarioId={usuarioId}
          filterParams={filterParams}
        />

        <ModalConfirm
          isDelete
          showButtonCancel={false}
          loading={false}
          modalType="update"
          icon="warning sign"
          iconColor="yellow"
          open={openModalAviso}
          buttonColor="violet"
          buttonText="Confirmar"
          confirm={closeModal}
          close={closeModal}
          message="Atenção"
          messageAfterIcon={
            <Fragment>
              <span>
                Você precisa informar um imóvel no atendimento para {textoAviso}
                .
              </span>
            </Fragment>
          }
        />

        <ModalGenerico
          size="small"
          close={closeModalPerdido}
          open={openModalPerdido}
          title="Registrar motivo">
          <AtendimentoPerdido
            isLoading={isUpdating}
            cardDetails={{
              id: cardDetails?.id,
              cliente: {
                nome: cardDetails?.clienteNome,
              },
            }}
            onClose={closeModalPerdido}
            onSubmit={updateStatusToPerdido}
          />
        </ModalGenerico>

        <ModalGenerico
          close={closeModal}
          open={openModalAtendimento}
          title="Registrar atendimento">
          <RegistroAtendimento
            modo={MODO.CADASTRAR}
            isChangeSituacaoAtendimento={isChangeSituacaoAtendimento}
            onFiltros={onFiltros}
            closeModal={closeModal}
            celular={selectedCard?.clienteTelefone}
            atendimentoId={selectedCard?.id}
            imovelId={selectedCard?.imovelId}
            statusEmAtendimento={true}
          />
        </ModalGenerico>

        <ModalGenerico
          close={closeModal}
          open={openModalVisita}
          title="Agendar visita">
          <AgendarVisita
            modo={MODO.CADASTRAR}
            isChangeSituacaoAtendimento={isChangeSituacaoAtendimento}
            onFiltros={onFiltros}
            closeModal={closeModal}
            atendimentoId={selectedCard?.id}
            imovelId={selectedCard?.imovelId}
          />
        </ModalGenerico>

        <ModalGenerico
          close={closeModal}
          open={openModalProposta}
          title="Fazer proposta">
          <Proposta
            modo={MODO.CADASTRAR}
            closeModal={closeModalProposta}
            atendimentoId={selectedCard?.id}
            imovelId={selectedCard?.imovelId}
            imovel={{
              imagens: [
                {
                  arquivo: selectedCard?.imovelImagem,
                },
              ],
              tituloAmigavel: selectedCard?.imovelDescricao,
              codImovel: selectedCard?.imovelCodigo,
              valorVenda: selectedCard?.imovelValor,
              valorAluguel: selectedCard?.imovelValor,
              comissaoVenda: selectedCard?.imovelComissao,
              operacaoImobiliaria: selectedCard?.imovelOperacaoImobiliaria,
            }}
            cliente={{
              id: selectedCard?.clienteId,
              nome: selectedCard?.clienteNome,
            }}
            onSubmit={onSubmitProposta}
          />
        </ModalGenerico>
        <ModalGenerico
          maxHeight={'85vh'}
          close={closeModalOnePlus}
          size="fullscreen"
          open={openModalOnePlus}
          requireConfirmation={true}
          trigger={<Button>Scrolling Content Modal</Button>}
          title="Controle de Propostas | Secretaria de Vendas">
          <iframe
            title="Controle de Propostas | Secretaria de Vendas"
            onpost
            style={{border: 0, padding: 0}}
            height={`${window.innerHeight - 120}px`}
            width="100%"
            src={`https://mybroker-nextjs.vercel.app/auth/signin?username=${userCpf}&lead=${selectedCard?.clienteId}&atdId=${selectedCard?.id}&imovelId=${selectedCard?.imovelId}`}
          />
        </ModalGenerico>
      </Page>
    </Fragment>
  );
}

export default ConsultaAtendimentos;
