/* eslint-disable */
import React, {useState, useEffect, Fragment} from 'react';

import {Switch, Route} from 'react-router-dom';
import {authenticationService} from '~/services/authentication.service';
import * as Routes from './index';

import LatitudeLayout from '~/components/Layout';
import history from '~/services/history';
import Profile from '~/views/private/Profile';
import Oferta from '~/views/private/OfertaAtiva/Cadastro';
import OfertaListaLeads from '~/views/private/OfertaAtiva/Consulta/ListaLeads';
import PerfilUsuario from '~/views/private/Cadastros/PerfilUsuario';
import PerfilMenu from '~/views/private/Cadastros/PerfilMenu';
import ManutencaoTipoMidia from '~/views/private/Cadastros/TipoMidia/Manutencao';
import ManutencaoAtendimento from '~/views/private/Cadastros/Atendimentos/Manutencao';
import ManutencaoGrupoCorretores from '~/views/private/Cadastros/GrupoCorretores/Manutencao';
import ManutencaoTipoEstabelecimento from '~/views/private/Cadastros/TipoEstabelecimento/Manutencao';
import ManutencaoTipoCondominio from '~/views/private/Cadastros/TipoCondominio/Manutencao';
import ManutencaoFinalidades from '~/views/private/Cadastros/Finalidades/Manutencao';
import ManutencaoTipoImovel from '~/views/private/Cadastros/TipoImovel/Manutencao';
import ManutencaoTipoAtendimento from '~/views/private/Cadastros/TipoAtendimento/Manutencao';
import ManutencaoTipoLogradouro from '~/views/private/Cadastros/TipoLogradouro/Manutencao';
import ManutencaoTipoHonorario from '~/views/private/Cadastros/TipoHonorario/Manutencao';
import ManutencaoTipoSolo from '~/views/private/Cadastros/TipoSolo/Manutencao';
import ManutencaoPerfil from '~/views/private/Cadastros/Perfil/Manutencao';
import ManutencaoPerfilAcesso from '~/views/private/Cadastros/PerfilAcesso/Manutencao';
import ManutencaoEmpresa from '~/views/private/Cadastros/Empresa/Manutencao';
import ManutencaoBairros from '~/views/private/Cadastros/Bairros/Manutencao';
import ManutencaoProfissoes from '~/views/private/Cadastros/Profissoes/Manutencao';
import ManutencaoFormacoes from '~/views/private/Cadastros/Formacoes/Manutencao';
import ManutencaoLogradouro from '~/views/private/Cadastros/Logradouro/Manutencao';
import ManutencaoCondominio from '~/views/private/Cadastros/Condominio/Manutencao';
import ManutencaoImovel from '~/views/private/Cadastros/Imoveis/Manutencao';
import EmpresaIntegracao from '~/views/private/Cadastros/Empresa/Integracao';
import SiteExterno from '~/views/private/SiteExterno';
import PreviewImovel from '~/views/private/Cadastros/Imoveis/Preview';
import ModalUsuarioAssociado from './components/ModalUsuarioAssociado';
import ManutencaoMaster from '~/views/private/Cadastros/Master/Manutencao';
import ManutencaoCorretor from '~/views/private/Cadastros/Corretor/Manutencao';
import ManutencaoPessoa from '~/views/private/Cadastros/Pessoa/Manutencao';
import ManutencaoPlantoes from '~/views/private/Cadastros/Plantao/Manutencao';
import ManutencaoMenu from '~/views/private/Cadastros/Menu/Manutencao';
import ManutencaoAcoes from '~/views/private/Cadastros/Acoes/Manutencao';
import ManutencaoComidaFavorita from '~/views/private/Cadastros/ComidaFavorita/Manutencao';
import EntreAgencias from '~/views/private/Cadastros/EntreAgencias';
import FacebookLoginRedirect from '~/views/private/Configuracoes/components/distribuicao/components/facebookLoginRedirect';

import ModalEmpresas from '~/components/ModalEmpresas';

import pt from '~/infra/resources/strings/pt';
import url from '~/infra/urls';
import api from '~/services/api';

import fixedMenus from '~/infra/resources/strings/enums/fixedMenus';

import {encrypt, decrypt} from '~/infra/resources/pattern/cripto';
import 'moment/locale/pt-br';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import Distribuicao from '~/views/private/Configuracoes/components/distribuicao/distribuicao';
moment.locale('pt-BR');

export function renderRouteItem(route, index, props) {
  let retorno;
  if (route?.children === undefined) {
    retorno = (
      <Route
        key={index}
        path={`${props.match.path}${route?.url}`}
        exact
        component={Routes[route?.component]}
      />
    );
  }

  return retorno;
}

export function goMenuItem(props, url, identificador) {
  history.push(
    `${props.match.path}${url.replace(':identificador', identificador)}`,
  );
}

export function getDinamycRoutes() {
  const encryptedMenus = localStorage.getItem('icaseMenus');
  let rolesConfig = {};
  if (encryptedMenus) {
    const decryptedMenus = decrypt(encryptedMenus);
    const grantedMenus = JSON.parse(decryptedMenus) || [];
    const structuredMenus = grantedMenus.map((menu) => {
      return {
        identificador: menu.identificador,
        component: menu.componente,
        url: menu.url ? `${menu.url}/:identificador` : '',
        urlExterna: menu?.urlExterna,
        novaAba: menu?.novaAba,
        title: menu.descricao,
        heading: menu.descricao,
        icon: menu.icone,
        actions: menu.acoes,
        filhos: menu?.filhos
          ? menu.filhos.map((filho) => {
              return {
                identificador: menu.identificador,
                component: filho.componente,
                url: filho.url ? `${filho.url}/:identificador` : '',
                title: filho.descricao,
                heading: menu.descricao,
                icon: menu.icone,
                actions: menu.acoes,
              };
            })
          : [],
      };
    });

    const encryptStructuredMenus = encrypt(
      JSON.stringify(structuredMenus) || [],
    );

    localStorage.setItem('icaseStructuredMenus', encryptStructuredMenus);

    rolesConfig = {
      SystemAdmin: {
        routes: [
          {
            component: 'Cadastros',
            url: '',
            title: 'Cadastros',
            heading: 'Cadastros',
            icon: 'AppstoreAddOutlined',
            filhos: [
              {
                component: 'Acoes',
                url: '/acoes',
                title: 'Ações',
                heading: 'Cadastros',
                icon: '',
              },
              {
                component: 'Menu',
                url: '/menu',
                title: 'Menu',
                heading: 'Cadastros',
                icon: '',
              },
              {
                component: 'Empresa',
                url: '/empresa',
                title: 'Empresa',
                heading: 'Cadastros',
                icon: '',
              },
              {
                component: 'Master',
                url: '/master',
                title: 'Master',
                heading: 'Cadastros',
                icon: '',
              },
            ],
          },
          {
            component: 'ListagemUrl',
            url: '/listagem-urls',
            title: 'Listagem de URLs',
            heading: '',
            icon: 'SwitcherOutlined',
          },
          {
            component: 'Imoveis',
            url: '/imoveis',
            title: 'Imóveis',
            heading: '',
            icon: 'BankOutlined',
          },
          {
            component: 'ImportacaoEmpresa',
            url: '/empresa/integracao',
            title: 'Importação',
            heading: 'Cadastros',
            icon: 'DownloadOutlined',
          },
        ],
      },
      Master: {
        routes: fixedMenus,
      },
      Diretor: {
        routes: structuredMenus,
      },
      AdmMatriz: {
        routes: structuredMenus,
      },
      Gerente: {
        routes: structuredMenus,
      },
      Corretor: {
        routes: structuredMenus,
      },
      common: {
        routes: [
          {
            component: 'Dashboard',
            url: '/',
            title: 'Dashboard',
            heading: 'Gestão',
            filhos: [],
            icon: 'DashboardOutlined',
          },
        ],
      },
    };
  } else {
    authenticationService.logout();
    history.push('/');
  }

  return rolesConfig;
}

function PrivateRoutes(props) {
  const [nome, setIsNome] = useState('');
  const [modalUsuarioAssociado, setModalUsuarioAssociado] = useState(false);
  const [modalEmpresas, setModalEmpresas] = useState(false);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState();
  const [BoardRoutes, setBoardRoutes] = useState([]);
  const [BoardURLRoutes, setBoardURLRoutes] = useState([]);
  const [ChildrenActive, setChildrenActive] = useState(' ');
  const [icaseEmpresas, setIcaseEmpresas] = useState([]);

  const listEmpresas = JSON.parse(localStorage.getItem('icaseEmpresas')) || [];
  let currentUser = JSON.parse(localStorage.getItem('icaseUser'));
  const logoEmpresa = localStorage.getItem('icaseLogoEmpresa');
  const icasePessoaId = undefined;

  useEffect(() => {
    let auxIcaseEmpresas = localStorage.getItem('icaseEmpresas');
    let auxIcaseRoles = localStorage.getItem('icaseRoles');
    let auxCurrentUser = localStorage.getItem('icaseUser');

    if (auxIcaseEmpresas) {
      auxIcaseEmpresas = JSON.parse(auxIcaseEmpresas);
      setIcaseEmpresas(auxIcaseEmpresas);
    }
    if (auxIcaseRoles) {
      auxIcaseRoles = JSON.parse(auxIcaseRoles);
    }
    if (auxCurrentUser) {
      auxCurrentUser = JSON.parse(auxCurrentUser);
    }

    if (icasePessoaId === 0) {
      setModalUsuarioAssociado(true);
    }

    if (auxCurrentUser) {
      const {nomeCompleto} = auxCurrentUser;
      localStorage.setItem('nomeCompleto', nomeCompleto);

      if (nomeCompleto) {
        const name = nomeCompleto.split(' ');
        setIsNome(name[0]);
      } else {
        setIsNome('Senhor(a)');
      }
    }

    let rolesConfig = {};
    if (auxIcaseRoles && auxCurrentUser) {
      rolesConfig = getDinamycRoutes();
    }

    if (auxIcaseRoles && Object.keys(rolesConfig).length > 0) {
      let roles = ['common', ...auxIcaseRoles];
      let childrenRoutes = [];

      let allowedRoutes = roles.reduce((acc, role) => {
        if (rolesConfig[role]?.routes) {
          rolesConfig[role].routes.map((children) => {
            if (children?.filhos && children?.filhos?.length >= 1) {
              childrenRoutes.push(children.filhos);
            }
          });

          return [...acc, ...rolesConfig[role].routes];
        }

        return [...acc];
      }, []);

      allowedRoutes = allowedRoutes.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.component === item.component),
      );

      setBoardRoutes(allowedRoutes);
      setBoardURLRoutes(allowedRoutes.concat(childrenRoutes[0]));
    } else {
      authenticationService.logout();
      history.push('/');
    }
  }, []);

  function modalAssociadoClose() {
    setModalUsuarioAssociado(false);
  }

  function navToProfile() {
    history.push('/app/perfil');
  }

  function sair() {
    authenticationService.logout();
  }

  function changeCompany() {
    setModalEmpresas(true);
  }

  function closeModalEmpresas() {
    setModalEmpresas(false);
  }

  function handleSelectedEmpresaId(empresaId) {
    setSelectedEmpresaId(empresaId);
  }

  async function handleLoginEmpresa() {
    await api
      .post(url.LOGIN.CHANGE_COMPANY(selectedEmpresaId))
      .then((user) => {
        const {
          jwt,
          id: usuarioId,
          empresaId,
          logoEmpresa,
          roles,
          menusGranted,
        } = user.data.data;

        const {token, refreshToken} = jwt;
        const {unique_name: userCpf, exp} = jwtDecode(token);

        localStorage.setItem('icaseUserCpf', userCpf);
        localStorage.setItem('icaseEmpresaId', empresaId);
        localStorage.setItem('icaseToken', token);
        localStorage.setItem('icasetokenExpiration', exp);
        localStorage.setItem('icaseRefreshToken', JSON.stringify(refreshToken));
        localStorage.setItem('icaseUsuarioId', usuarioId);
        localStorage.setItem('icaseUser', JSON.stringify(user.data.data));

        if (logoEmpresa) localStorage.setItem('icaseLogoEmpresa', logoEmpresa);
        else localStorage.removeItem('icaseLogoEmpresa');

        let perfis = [];
        roles.map((item) => {
          perfis.push(item);
        });

        const master_leads = perfis.find((perfil) => perfil === 'MASTER LEADS');

        if (master_leads) {
          localStorage.setItem('icaseMasterLeads', true);
        } else {
          localStorage.setItem('icaseMasterLeads', false);
        }

        localStorage.setItem('icaseRoles', JSON.stringify(roles));

        const {menus} = menusGranted ? menusGranted : [];

        const encryptedMenus = encrypt(JSON.stringify(menus || []));

        localStorage.setItem('icaseMenus', encryptedMenus);

        history.push('/');
      })
      .catch((err) => {
        const {errors} = err.response.data;
        if (errors) {
          for (const item in errors) {
            Notify('error', item ? item : pt.comum.atencao, errors[item]);
          }
        }
      });
  }

  return (
    <Fragment>
      <LatitudeLayout menuItems={BoardRoutes} props={props}>
        <div className="content">
          <Switch>
            {BoardURLRoutes.map((route, index) =>
              renderRouteItem(route, index, props),
            )}
            <Route
              path="/app/siteExterno/:urlExterna"
              exact
              component={SiteExterno}
            />
            <Route path="/app/perfil" exact component={Profile} />
            <Route path="/app/oferta" exact component={Oferta} />
            <Route
              path="/app/oferta/listaLeads"
              exact
              component={OfertaListaLeads}
            />

            <Route path="/app/perfilusuario" exact component={PerfilUsuario} />
            <Route path="/app/perfilmenu" exact component={PerfilMenu} />
            <Route
              path="/app/grupocorretores/manutencao/:modo"
              exact
              component={ManutencaoGrupoCorretores}
            />
            <Route
              path="/app/tipoestabelecimento/manutencao/:modo"
              exact
              component={ManutencaoTipoEstabelecimento}
            />
            <Route
              path="/app/tipocondominio/manutencao/:modo"
              exact
              component={ManutencaoTipoCondominio}
            />
            <Route
              path="/app/finalidades/manutencao/:modo"
              exact
              component={ManutencaoFinalidades}
            />
            <Route
              path="/app/tipoimovel/manutencao/:modo"
              exact
              component={ManutencaoTipoImovel}
            />
            <Route
              path="/app/tipoatendimento/manutencao/:modo"
              exact
              component={ManutencaoTipoAtendimento}
            />
            <Route
              path="/app/tipomidia/manutencao/:modo"
              exact
              component={ManutencaoTipoMidia}
            />
            <Route
              path="/app/tipologradouro/manutencao/:modo"
              exact
              component={ManutencaoTipoLogradouro}
            />
            <Route
              path="/app/tipohonorario/manutencao/:modo"
              exact
              component={ManutencaoTipoHonorario}
            />
            <Route
              path="/app/tiposolos/manutencao/:modo"
              exact
              component={ManutencaoTipoSolo}
            />
            <Route
              path="/app/bairros/manutencao/:modo"
              exact
              component={ManutencaoBairros}
            />
            <Route
              path="/app/profissoes/manutencao/:modo"
              exact
              component={ManutencaoProfissoes}
            />
            <Route
              path="/app/formacoes/manutencao/:modo"
              exact
              component={ManutencaoFormacoes}
            />
            <Route
              path="/app/logradouros/manutencao/:modo"
              exact
              component={ManutencaoLogradouro}
            />
            <Route
              path="/app/condominios/manutencao/:modo"
              exact
              component={ManutencaoCondominio}
            />
            <Route
              path="/app/empresa/manutencao/:modo"
              exact
              component={ManutencaoEmpresa}
            />
            <Route
              path="/app/empresa/integracao"
              exact
              component={EmpresaIntegracao}
            />
            <Route
              path="/app/perfil/manutencao/:modo"
              exact
              component={ManutencaoPerfil}
            />
            <Route
              path="/app/perfilacesso/manutencao/:modo"
              exact
              component={ManutencaoPerfilAcesso}
            />
            <Route
              path="/app/imoveis/manutencao/:modo/:identificador"
              exact
              component={ManutencaoImovel}
            />
            <Route
              path="/app/imoveis/manutencao/:modo/:identificador/:id"
              exact
              component={ManutencaoImovel}
            />
            <Route
              path="/app/imoveis/preview/:modo/:identificador/:id"
              exact
              component={PreviewImovel}
            />
            <Route
              path="/app/atendimentos/manutencao/:modo"
              exact
              component={ManutencaoAtendimento}
            />
            <Route
              path="/app/atendimentos/novo-board"
              exact
              component={Routes.AtendimentosClone}
            />
            <Route
              path="/app/master/manutencao/:modo"
              exact
              component={ManutencaoMaster}
            />
            <Route
              path="/app/corretor/manutencao/:modo"
              exact
              component={ManutencaoCorretor}
            />
            <Route
              path="/app/pessoa/manutencao/:modo"
              exact
              component={ManutencaoPessoa}
            />
            <Route
              path="/app/plantoes/manutencao/:modo"
              exact
              component={ManutencaoPlantoes}
            />
            <Route
              path="/app/menu/manutencao/:modo"
              exact
              component={ManutencaoMenu}
            />
            <Route
              path="/app/acoes/manutencao/:modo"
              exact
              component={ManutencaoAcoes}
            />
            <Route
              path="/app/comidafavorita/manutencao/:modo"
              exact
              component={ManutencaoComidaFavorita}
            />
            <Route
              path="/app/imoveis-compartilhados"
              exact
              component={EntreAgencias}
            />
            <Route
              path="/app/facebook"
              exact
              component={FacebookLoginRedirect}
            />
            <Route
              path="/app/distribuicao/:modo"
              exact
              component={Distribuicao}
            />
          </Switch>
        </div>
      </LatitudeLayout>
      <ModalUsuarioAssociado
        open={modalUsuarioAssociado}
        close={() => modalAssociadoClose()}
      />

      <ModalEmpresas
        open={modalEmpresas}
        close={closeModalEmpresas}
        selectedEvent={handleSelectedEmpresaId}
        confirmEvent={handleLoginEmpresa}
        listEmpresas={listEmpresas}
        textTitle={'Selecione uma empresa'}
      />
    </Fragment>
  );
}

export default PrivateRoutes;
