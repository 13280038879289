import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useOfertaAtiva(current, pageSize, nome, grupoCorretoresId, status, dateRange) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const filterCurrent = current ? current : 1;
    const filterPageSize = pageSize ? pageSize : 12;
    const filterNomeCliente = nome ? nome : null;
    const filterGrupoCorretorId = grupoCorretoresId ? grupoCorretoresId : null;
    const filterStatusPagina = status ? status : null;

    let dataIni = undefined;
    let dataFim = undefined;
    if (dateRange && (dateRange[0] || dateRange[1])) {
      dataIni = dateRange[0]?.format('YYYY-MM-DD');
      dataFim = dateRange[1]?.format('YYYY-MM-DD');
    }

    let filter = '';

    if (filterCurrent) filter = `?numeroPagina=${filterCurrent}`;

    if (filterPageSize) {
      if (filter.length > 0) filter += `&tamanhoPagina=${filterPageSize}`;
      else filter = `?tamanhoPagina=${filterPageSize}`;
    }

    if (filterNomeCliente) {
      if (filter.length > 0) filter += `&nome=${filterNomeCliente}`;
      else filter = `?nome=${filterNomeCliente}`;
    }

    if (filterGrupoCorretorId) {
      if (filter.length > 0) filter += `&grupoCorretoresId=${filterGrupoCorretorId}`;
      else filter = `?grupoCorretoresId=${filterGrupoCorretorId}`;
    }

    if (filterStatusPagina) {
      if (filter.length > 0) filter += `&status=${filterStatusPagina}`;
      else filter = `?status=${filterStatusPagina}`;
    }

    if (dataIni) {
      if (filter.length > 0) filter += `&dataInicial=${dataIni}`;
      else filter = `?dataInicial=${dataIni}`;
    }

    if (dataFim) {
      if (filter.length > 0) filter += `&dataFinal=${dataFim}`;
      else filter = `?dataFinal=${dataFim}`;
    }

    try {
      await api.get(`${url.OFERTA_ATIVA.PROPOSTA_ATIVA_PAGED()}${filter}`)
        .then(res => {
          setData(res?.data?.data || []);
          setQuantidade(res?.data?.total || 0);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [current, pageSize, nome, grupoCorretoresId, status, dateRange]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
