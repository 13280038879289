import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardFooter } from 'reactstrap';
import { Select, Upload, Tooltip, Input } from 'antd';
import { Icon } from 'semantic-ui-react';
import { LoadingOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import useGrupoCorretores from '~/actions/DropDown/useGrupoCorretores';
import ModalGenerico from '~/components/ModalGenerico';


import Notify from '~/shared/Notify';
import Page from '~/components/Page';

import url from '~/infra/urls';
import api from '~/services/api';
import history from '~/services/history';
import Button from '~/shared/Button';
import planilhaExemplo from '~/assets/img/planilhaExemplo.png';
import useCorretorDropdown from '~/actions/DropDown/useCorretorDropdown';



const { Option } = Select;

function CadastroOfertaAtiva(props) {
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState();
  const [nome, setNome] = useState();
  const [grupoCorretorId, setGrupoCorretorId] = useState();
  const [corretoresId, setCorretoresId] = useState([]);
  const [openModalMaisInfo, setOpenModalMaisInfo] = useState(false);
  const [identificador, setIdentificador] = useState();

  const { data: dataGrupoCorretores } = useGrupoCorretores();
  const { data: dataCorretores } = useCorretorDropdown();


  useEffect(() => {
    if (props.match.params) {
      const { identificador } = props.location.state;
      setIdentificador(identificador);
    }     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);


  function voltar() {
    if (props?.onVoltar) {
      props.onVoltar();
    } else if (identificador) {
      history.push(`/app/oferta-ativa/${identificador}`);
    } else {
      history.push('/app/oferta-ativa');
    }
  }

  function salvar() {
    if (!nome) {
      Notify('error', '', 'Favor informe um Nome para a Oferta Ativa.');
      return;
    }

    if (fileData) {
      if (!grupoCorretorId && (!corretoresId || corretoresId.length === 0)) {
        Notify('error', '', 'Favor selecionar um grupo ou ao menos um corretor.');
        return;
      }

      setLoading(true);
      const formData = new FormData();
      formData.append('nome', nome);
      formData.append('arquivo', fileData);

      if (grupoCorretorId)
        formData.append('grupoCorretoresId', grupoCorretorId);

      if (corretoresId && corretoresId.length > 0)
        formData.append('corretoresId', corretoresId);

      formData.append('fornecedor', 1);

      api
        .post(url.OFERTA_ATIVA.CADASTRAR(), formData)
        .then((res) => {
          setLoading(false);
          Notify('success', '', 'Arquivo carregado com sucesso!');
          if (identificador) {
            history.push(`/app/oferta-ativa/${identificador}`);
          } else {
            history.push('/app/oferta-ativa');
          }
        })
        .catch((err) => {
          setLoading(false);
          const { error } = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', '', error[item]);
            }
          }
        });

    } else {
      Notify('error', '', 'Favor carregar um arquivo');
    }
  }


  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined
          style={{ fontSize: '24px', color: '#08c', fontWeight: 'bold' }}
        />
      ) : (
        <PlusOutlined
          style={{ fontSize: '24px', color: '#08c', fontWeight: 'bold' }}
        />
      )}
      <div style={{ marginTop: 8 }}>{fileData?.name ? <strong style={{ color: 'red' }}>{fileData?.name}</strong> : 'Upload'}</div>
    </div>
  );

  function handleUploadfile(info) {
    setFileData(info.file);
  }

  function onChangeGrupoCorretor(value) {
    setGrupoCorretorId(value);
  }

  return (
    <Fragment>
      <Page>
        <Row>
          <Col>
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Oferta ativa</h5>
                <p className="category">Importação de Leads</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" md="6">
                    <div className="p-2 mb-4">
                      <p className="heading-small text-muted mb-2">
                        <small>Nome</small>
                      </p>
                      <Input
                        style={{ width: '100%' }}
                        placeholder=" Descrição da oferta ativa"
                        onChange={(event) => setNome(event.target.value)} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="6">
                    <div className="p-2 mb-4">
                      <p className="heading-small text-muted mb-2">
                        <small>Grupo de Corretores</small>
                      </p>
                      <Select
                        style={{ width: '100%' }}
                        placeholder=" Grupo de Corretores"
                        onChange={onChangeGrupoCorretor}>
                        {dataGrupoCorretores.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.grupo}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Col>
                  <Col xs="12" md="6">
                    <div className="p-2 mb-4">

                      <p className="heading-small text-muted mb-2">
                        <small>Corretor(es)</small>
                      </p>
                      <Select
                        style={{ width: '100%' }}
                        placeholder=" Corretor(es)"
                        mode="multiple"
                        allowClear
                        showSearch
                        showArrow
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                            .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                        }
                        disabled={loading}
                        onChange={(value) => setCorretoresId(value)}
                      >
                        {dataCorretores.map((item) => (
                            <Option key={item.id} disabled={item.isSeparator}>
                              {item?.nome?.toString()}
                            </Option>
                        ))}
                      </Select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="p-2 mb-4">
                      <p>
                        Clique abaixo para enviar a planilha de oferta
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="1">
                    <div className="p-2 mb-4">
                      <p>
                        <Tooltip placement='top' title="Modelo de planilha de dados">
                          <Button
                            onClick={() => setOpenModalMaisInfo(true)}
                            icon
                            labelPosition="left"
                            size="tiny"
                            fluid
                            color="violet"
                            disabled={loading}>
                            <QuestionCircleOutlined
                              style={{ fontSize: '24px', color: '#FFF', fontWeight: 'bold' }}
                            />
                          </Button>
                        </Tooltip>
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="p-2 mb-4">
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        customRequest={handleUploadfile}>
                        {uploadButton}
                      </Upload>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col xs="12" md="4">
                    <Button
                      disabled={loading}
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-3"
                      color="violet"
                      onClick={salvar}>
                      <Icon name="check" />
                      Cadastrar
                    </Button>
                  </Col>
                  <Col xs="12" md="4">
                    <Button
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-3"
                      onClick={voltar}>
                      <Icon name="reply" />
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Page>
      <ModalGenerico
        open={openModalMaisInfo}
        close={() => setOpenModalMaisInfo(false)}>
        <Fragment>
          <Page>
            <Row>
              <Col xs="12">
                <p className="p-2 mb-4">
                  Modelo de planilha de dados para importação de oferta ativa:
                </p>
                <img
                  className="mr-3 h-50 align-self-end"
                  src={planilhaExemplo}
                  alt="..."
                />
                <p className="p-2 mt-4">
                  Obs: O formato do arquivo deve ser <strong>XLSX</strong>
                </p>
              </Col>
            </Row>
          </Page>
        </Fragment>
      </ModalGenerico>
    </Fragment>
  );
}

export default CadastroOfertaAtiva;
