import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useUsuario(filtro, filiaisId) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const payLoad = { filtro, filiaisId }
    try {
      await api
        .post(url.USUARIO.CONSULTA_FILTRO(), payLoad)
        .then((res) => {
          setData(res?.data || []);
          setQuantidade(res?.data?.data?.length);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [filtro, filiaisId]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}

