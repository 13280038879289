import React from 'react';

import styled from 'styled-components';

import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

const Container = styled.div`
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 2 2px #333;
  }
`;

export default function DraggableItem(props) {
  const {attributes, listeners, setNodeRef, transform, isOver} = useSortable({
    id: props.id,
    data: {
      type: 'Card',
      props: props?.card,
    },
  });

  return (
    <Container
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      style={{
        opacity: isOver ? 0.5 : 1,
        transition: 'border 0.2s ease-in-out',
        borderRadius: '3px',
        border: isOver ? '2px dashed #333' : '2px dashed transparent',
        transform: CSS.Transform.toString(transform),
      }}>
      {props.children}
    </Container>
  );
}

