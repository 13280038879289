/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Col, Row } from 'reactstrap';
import { DatePicker, Form, Select } from 'antd';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';

import DadosChave from '~/components/DadosChave';
import ModalAnaliseFinanceira from '~/components/ModaAnaliseFinanceira';
import ModalOcorrencia from '~/components/ModalOcorrencia';

import useDashFechamento from '~/actions/Dashboard/useDashFechamento';
import useLeadOfertaCorretor from '~/actions/OfertaAtiva/useLeadOfertaCorretor';
import useConfigSistema from '~/actions/Empresa/useConfigSistema';
import AtendimentosDonut from './components/AtendimentosDonut';
// import Aniversariantes from './components/Aniversariantes';
import ImoveisPizza from './components/ImoveisPizza';
import OfertaAtivaCorretor from './components/OfertaAtivaCorretor';
import * as configConst from '~/infra/config/configSistemaConst';

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import ModalImpostoRenda from './components/ModalImpostoRenda';
import useCorretorDropdown from '~/actions/DropDown/useCorretorDropdown';
import useLocalStorage from '~/hooks/localstorage/useLocalStorage'
moment.locale('pt-BR');

const dateFormat = 'MM/YYYY';
const { Option } = Select;
const { RangePicker } = DatePicker;

function DashboardCorretor() {
  const [form] = Form.useForm();
  const [dateMonth, setDateMonth] = useState([]);
  const [atendente, setAtendente] = useState(null);
  const [analiseFinanceiraOpen, setAnaliseFinanceiraOpen] = useState(false);
  const [loadAnaliseFinanceira, setLoadAnaliseFinanceira] = useState(true);
  const [qtdAnaliseFinanceira, setQtdAnaliseFinanceira] = useState(0);
  const [ocorrenciaAnaliseOpen, setOcorrenciaAnaliseOpen] = useState(false);
  const [ocorrenciaAprovadaOpen, setOcorrenciaAprovadaOpen] = useState(false);
  const [ocorrenciaPendenteOpen, setOcorrenciaPendenteOpen] = useState(false);
  const [loadOcorrencia, setLoadOcorrencia] = useState(false);
  const [qtdOcorrenciaAnalise, setQtdOcorrenciaAnalise] = useState(0);
  const [qtdOcorrenciaAprovada, setQtdOcorrenciaAprovada] = useState(0);
  const [qtdOcorrenciaPendente, setQtdOcorrenciaPendente] = useState(0);
  const [impostoRendaOpen, setImpostoRendaOpen] = useState(false);
  const [loadImpostoRenda, setLoadImpostoRenda] = useState(true);
  const [loadFechamento, setLoadFechamento] = useState(true);
  const [qtdImpostoRenda, setQtdImpostoRenda] = useState(0);
  const [ofertaAtivaSelecionada, setOfertaAtivaSelecionada] = useLocalStorage('OfertaAtivaSelecionada', null);

  const { checkConfig } = useConfigSistema();

  const { data: oferta, onLoad: onLoadOfertas, isLoading: isLoadingOfertas } = useLeadOfertaCorretor(localStorage.getItem('icaseUsuarioId'));
  const { data: fechamento } = useDashFechamento(atendente);
  const { data: corretores } = useCorretorDropdown();

  if (corretores) {
    let todos = false;
    corretores.forEach((item) => {
      if (item.id === 0) todos = true;
    });

    if (todos === false) {
      corretores.unshift({
        id: 0,
        nome: 'TODOS',
      });
    }
  }


  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const selectCorretores =
    !!roles.find((x) => x.toUpperCase() === 'MASTER') ||
    !!roles.find((x) => x.toUpperCase() === 'DIRETOR') ||
    !!roles.find((x) => x.toUpperCase() === 'ADMMATRIZ') ||
    !!roles.find((x) => x.toUpperCase() === 'GERENTE');
  const masterLeads =
    localStorage.getItem('icaseMasterLeads') === 'false' ? false : true;
  const hasSelectCorretor = corretores.length >= 1;
  const hasOfertas = !!oferta;

  /* Caso a oferta ativa selecionada não exista mais, limpa do localstorage */
  useEffect(() => {
    if (!isLoadingOfertas && ofertaAtivaSelecionada && !hasOfertas) {
      setOfertaAtivaSelecionada(null);
    }
  }, [ofertaAtivaSelecionada, setOfertaAtivaSelecionada, hasOfertas, isLoadingOfertas]);

  useEffect(() => {
    setTimeout(() => {
      setLoadAnaliseFinanceira(false);
      setLoadImpostoRenda(false);
      setLoadFechamento(false);
    }, 1200);
  }, [fechamento, dateMonth]);

  function onChangeData(date, dateString) {
    setDateMonth(dateString);
  }

  function onChangeAtendente(value) {
    setAtendente(value === '0' ? null : value);
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <Card className="p-2">
            <CardHeader>
              <Form form={form}>
                <Row>
                  <Col xs="12" md="3">
                    <div className="my-2">
                      <h5 className="title">Dashboard</h5>
                      <p className="category">Dados e Métricas</p>
                    </div>
                  </Col>

                  {(selectCorretores || masterLeads) && hasSelectCorretor ? (
                    <Col xs="12" md="3">
                      <div className="my-2">
                        <Select
                          defaultValue={String(0)}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                              .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                          }
                          placeholder=" Atendente"
                          style={{ width: '100%' }}
                          onChange={onChangeAtendente}>
                          {corretores.map((item) => (
                              <Option key={item.id} disabled={item.isSeparator}>
                                {item?.nome?.toString()}
                              </Option>
                          ))}
                        </Select>
                      </div>
                    </Col>
                  ) : (
                    <Col xs="12" md="3"></Col>
                  )}

                  <Col xs="12" md={hasOfertas ? '3' : '6'}>
                    <div className="my-2">
                      <RangePicker
                        onChange={(date, dateString) =>
                          onChangeData(date, dateString)
                        }
                        locale={locale}
                        format={dateFormat}
                        picker="month"
                        style={{ width: '100%' }}
                      />
                    </div>
                  </Col>

                  {hasOfertas ? (
                    <OfertaAtivaCorretor grupoCorretoresId={oferta?.grupoCorretoresId} onReponse={onLoadOfertas} />
                  ) : (
                    <Col xs="12" md="3"></Col>
                  )}
                </Row>
              </Form>
            </CardHeader>
          </Card>
        </Col>
      </Row>

      <DadosChave
        atendente={atendente}
        fechamento={fechamento}
        loadFechamento={loadFechamento}
        dateMonth={dateMonth}
        qtdAnaliseFinanceira={qtdAnaliseFinanceira}
        qtdImpostoRenda={qtdImpostoRenda}
        setAnaliseFinanceiraOpen={(v) => setAnaliseFinanceiraOpen(v)}
        loadAnaliseFinanceira={loadAnaliseFinanceira}
        setImpostoRendaOpen={(v) => setImpostoRendaOpen(v)}
        loadImpostoRenda={loadImpostoRenda}
      />

      <Row className="px-2">
        <Col sm="12" md="6">
          <div className="flex-grow-1 my-2">
            {loadAnaliseFinanceira ? (
              <div className="dash-container my-2">
                <SkeletonTableBasic cardShadowless />
              </div>
            ) : (
              <Card>
                <CardBody>
                  <div className="dash-container-graph">
                    <AtendimentosDonut idCorretor={atendente} />
                  </div>
                </CardBody>
              </Card>
            )}
          </div>
        </Col>
        <Col sm="12" md="6">
          <div className="flex-grow-1 my-2">
            {loadAnaliseFinanceira ? (
              <div className="dash-container my-2">
                <SkeletonTableBasic cardShadowless />
              </div>
            ) : (
              <Card>
                <CardBody>
                  <div className="dash-container-graph">
                    <ImoveisPizza idCorretor={atendente} />
                  </div>
                </CardBody>
              </Card>
            )}
          </div>
        </Col>
      </Row>

      {/* <Aniversariantes idCorretor={atendente} /> */}

      {checkConfig(configConst.CONTROLA_OCORRENCIAS) && (
        <Row className="px-2">
          <div className="d-flex my-2 w-100 flex-wrap">
            {(selectCorretores || masterLeads) && (
              <div className="flex-grow-1 card-dash">
                {loadOcorrencia ? (
                  <div className="dash-container my-2">
                    <SkeletonTableBasic cardShadowless />
                  </div>
                ) : (
                  <Card>
                    <CardBody>
                      <div
                        style={{ cursor: 'pointer' }}
                        className="dash-container"
                        onClick={() => setOcorrenciaAnaliseOpen(true)}>
                        <p className="dash-content-title">
                          Ocorrência
                          <span className="dash-content-title-number"> </span>
                        </p>
                        <div className="tag-occurrence-approve">Aprovada</div>
                        <div className="dash-occurrence-number">
                          {qtdOcorrenciaAprovada || 0}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                )}
              </div>
            )}

            <div className="flex-grow-1 card-dash">
              {loadOcorrencia ? (
                <div className="dash-container my-2">
                  <SkeletonTableBasic cardShadowless />
                </div>
              ) : (
                <Card>
                  <CardBody>
                    <div
                      style={{ cursor: 'pointer' }}
                      className="dash-container"
                      onClick={() => setOcorrenciaAnaliseOpen(true)}>
                      <p className="dash-content-title">
                        Ocorrência
                        <span className="dash-content-title-number"> </span>
                      </p>
                      <div className="tag-occurrence-analysis">Em análise</div>
                      <div className="dash-occurrence-number">
                        {qtdOcorrenciaAnalise || 0}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )}
            </div>

            {(selectCorretores || masterLeads) && (
              <div className="flex-grow-1 card-dash">
                {loadOcorrencia ? (
                  <div className="dash-container my-2">
                    <SkeletonTableBasic cardShadowless />
                  </div>
                ) : (
                  <Card>
                    <CardBody>
                      <div
                        style={{ cursor: 'pointer' }}
                        className="dash-container"
                        onClick={() => setOcorrenciaAnaliseOpen(true)}>
                        <p className="dash-content-title">
                          Ocorrência
                          <span className="dash-content-title-number"> </span>
                        </p>
                        <div className="tag-occurrence-failed">Com Pendência</div>
                        <div className="dash-occurrence-number">
                          {qtdOcorrenciaPendente || 0}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                )}
              </div>
            )}
          </div>
        </Row>
      )}

      {
        checkConfig(configConst.UTILIZA_ANALISE_FINANCEIRA) && (
          <ModalAnaliseFinanceira
            close={() => setAnaliseFinanceiraOpen(false)}
            open={analiseFinanceiraOpen}
            onChangeQtd={(qtd) => {
              setLoadAnaliseFinanceira(false);
              setQtdAnaliseFinanceira(qtd);
            }}
          />
        )
      }

      {
        checkConfig(configConst.CONTROLA_IR) && (
          <ModalImpostoRenda
            close={() => setImpostoRendaOpen(false)}
            open={impostoRendaOpen}
            onChangeQtd={(qtd) => {
              setLoadImpostoRenda(false);
              setQtdImpostoRenda(qtd);
            }}
            status={1}
          />
        )
      }

      {
        checkConfig(configConst.CONTROLA_OCORRENCIAS) && (
          <>
            <ModalOcorrencia
              close={() => setOcorrenciaAnaliseOpen(false)}
              open={ocorrenciaAnaliseOpen}
              onChangeQtd={(qtd) => {
                setLoadOcorrencia(false);
                setQtdOcorrenciaAnalise(qtd);
              }}
              statusOcorrencia={1}
            />
            {(selectCorretores || masterLeads) && (
              <>
                <ModalOcorrencia
                  close={() => setOcorrenciaPendenteOpen(false)}
                  open={ocorrenciaPendenteOpen}
                  onChangeQtd={(qtd) => {
                    setLoadOcorrencia(false);
                    setQtdOcorrenciaPendente(qtd);
                  }}
                  statusOcorrencia={2}
                  atendente={atendente}
                />
                <ModalOcorrencia
                  close={() => setOcorrenciaAprovadaOpen(false)}
                  open={ocorrenciaAprovadaOpen}
                  onChangeQtd={(qtd) => {
                    setLoadOcorrencia(false);
                    setQtdOcorrenciaAprovada(qtd);
                  }}
                  statusOcorrencia={3}
                  atendente={atendente}
                />
              </>
            )}
          </>
        )
      }
    </Fragment >
  );
}

export default DashboardCorretor;
