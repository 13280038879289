import {useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

import 'moment/locale/pt-br';
import moment from 'moment';
import {FilterCache} from '~/shared/filterCache';
moment.locale('pt-BR');

export default function useAtendimentos() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);
  const [filtros, setFiltros] = useState(null);

  const payloadComposite = (obj, key, value) => {
    if (value) {
      return (obj[key] = value);
    }

    return null;
  };

  const onLoad = useCallback(async ({params}) => {
    const payload = {
      numeroPagina: params?.numeroPagina ? params?.numeroPagina : 1,
      tamanhoPagina: params?.tamanhoPagina ? params?.tamanhoPagina : 10,
      simpleList: true
    };

    if (params?.laneId) {
      payload['laneId'] = params?.laneId;
    }

    const paramsIsNotEmpty = params && Object.keys(params).length > 0;

    if (paramsIsNotEmpty) {
      for (const [key, value] of Object.entries(params)) {
        if (value?.length || typeof value === 'boolean') {
          payloadComposite(payload, key, value);
        } else if (!isNaN(parseFloat(value)) && isFinite(value)) {
          payloadComposite(payload, key, value);
        }
      }

      if (params?.isFirstRender) {
        setIsLoading(true);
      }
    }

    if (Object.keys(payload).length > 0) {
      if (params?.isFirstRender) {
        setIsLoading(true);
      }

      FilterCache.setFiltrosAtendimentos(payload);

      setFiltros(payload)

      try {
        await api
          .post(url.ATENDIMENTO.FILTER_AGRUPADO(), payload)
          .then((res) => {
            setData({lanes: res?.data?.data} || {lanes: []});
            setQuantidade(res?.data?.total);
          });
      } catch (e) {
        setData({lanes: []});
      } finally {
        if (params?.isFirstRender) {
          setIsLoading(false);
        }
      }
    }
  }, []);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
    filtros
  };
}
