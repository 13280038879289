import React, { Fragment } from 'react'
import { Button, Icon, Image, Modal } from 'semantic-ui-react'
import { Col, Row } from 'reactstrap'
import { SwapOutlined } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'
import PhoneLink from '~/components/PhoneLink/PhoneLink'

// Add separate line for assets
import newLead from '~/assets/img/NewLead.png';


const Loading = (props) => <Row className="justify-content-center">
  <div className="d-flex flex-nowrap mr-3">
    <div className="pt-4">
      {props.text || "Carregando..."}
    </div>
  </div>
</Row>

const ActionButton = (props) => (
  <Button
    className="my-3"
    size="tiny"
    icon
    labelPosition="left"
    fluid
    {...props}
  >
    <Icon name={props.icon} />
    {props.children}
  </Button>
)

const ModalActions = ({ loading, handleResponse, cliente }) => {
  const buttons = [
    {
      color: 'red',
      icon: 'times',
      label: 'Cliente Recusou',
      onClick: () => handleResponse(cliente, 3),
    },
    {
      icon: 'exclamation-triangle',
      label: 'Fone Inexistente',
      onClick: () => handleResponse(cliente, 6),
    },
    {
      icon: 'ban',
      label: 'Não Atendeu',
      onClick: () => handleResponse(cliente, 4),
    },
    {
      color: 'blue',
      icon: 'check',
      label: 'Iniciar Atendimento',
      onClick: () => handleResponse(cliente, 2),
    },
  ];

  return (
    <Modal.Actions>
      <Row className="justify-content-center mb-4 mx-2">
        {buttons.map((buttonProps, index) => (
          <Col key={index} xs="12" md="3" className="mb-2">
            <ActionButton
              className="my-2"
              size="tiny"
              labelPosition="left"
              fluid
              disabled={loading}
              icon={buttonProps.icon}
              color={buttonProps.color}
              onClick={buttonProps.onClick}
            >
              {buttonProps.label}
            </ActionButton>
          </Col>
        ))}
      </Row>
    </Modal.Actions>
  );
}


const Phone = ({ phone }) => (
  phone ? (
      <span className="font-weight-bold">
        <PhoneLink phone={phone} />
      </span>
    ) : null
)

const ModalHeader = ({ onClickTrocaLista, ofertaAtiva }) => (
  <Row className="mx-auto" style={{ display: "inline-table", width: "100%" }}>
    <Col xs="12" className="justify-content-center text-center">
      <div>
        <h2 className="font-weight-bold mb-4 text-center">
          Novo contato, ligue e oferte!
        </h2>
        <Row style={{    display: 'flex', justifyContent: 'center', gap: '15px'}}>
          <Text strong className="font-weight-bold font-size-1-5 md:text-center text-left">
            {`Lista: ${ofertaAtiva.nome}`}
          </Text>
          <span
            style={{ cursor: "pointer" }}
            onClick={onClickTrocaLista}>
            <SwapOutlined />
            <span className="p-3">
              Trocar de Lista
            </span>
          </span>
        </Row>
      </div>
    </Col>
  </Row>
)

const ModalOfertaAtiva = ({ loading, open, response, cliente, ofertaAtivaSelecionada, onTrocaLista }) => (
  <Fragment>
    <Modal
      closeOnDimmerClick={false}
      size="large"
      dimmer="blurring"
      open={open}
    >
      <Modal.Content image>
        <div className="m-2 text-center">
          <Image size="small" src={newLead} wrapped/>
        </div>
        <Col  className="p-2 w-100 text-centerv" style={{ display: "inline-table", width: "100%" }}>
          <ModalHeader
            ofertaAtiva={ofertaAtivaSelecionada}
            onClickTrocaLista={onTrocaLista}
          />
          {loading && <Loading/>}
          {!loading && (
            <Col xs={12} offset={7} className="justify-content-center text-center">
              <p className="mt-3 font-weight-light font-size-1-5">
                {cliente?.nomeCompleto}
              </p>
              <p className="my-2">Telefone(s)</p>

              <Phone phone={cliente?.telefone1}/>
              <Phone phone={cliente?.telefone2}/>
              <Phone phone={cliente?.telefone3}/>
              <Phone phone={cliente?.telefone4}/>

            </Col>)}
        </Col>
      </Modal.Content>
      <ModalActions loading={loading} handleResponse={response} cliente={cliente}/>
    </Modal>
  </Fragment>
)

export default ModalOfertaAtiva;
