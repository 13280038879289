import React from 'react';
import styled from 'styled-components';

import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import DraggableItem from './DraggableItem';
import {useDroppable} from '@dnd-kit/core';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;

  border-radius: 4px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  background-color: #eeee;

  .header {
    display: flex;
    flex-direction: column;
    gap: 0px;

    line-height: normal;

    .line-header-first-title {
      font-size: 16px;
      font-weight: 500;
    }

    .line-header-second-title {
    }
  }

  .rows {
    display: flex;
    flex-direction: column;
    gap: 8px;

    overflow-y: auto;

    width: 100%;
    height: 500px;

    padding: 10px;
  }
`;

export default function DroppableItem({
  id,
  title,
  cards,
  laneColor,
  Header,
  Card,
}) {
  const {setNodeRef: droppableRef} = useDroppable({
    id,
  });

  return (
    <Item>
      <div
        style={{
          width: '100%',
          height: 20,
          backgroundColor: laneColor,
          borderTopLeftRadius: 'inherit',
          borderTopRightRadius: 'inherit',
        }}
      />
      <div className="header">
        <Header
          id={id}
          title={title}
          showSelectedAllCheckbox={false}
          total={cards?.length || 0}
          cards={cards}
        />
      </div>
      <SortableContext
        id={id}
        items={cards.map((card) => card?.id)}
        strategy={verticalListSortingStrategy}>
        <div className="rows" ref={droppableRef}>
          {cards.map((card) => (
            <DraggableItem key={card?.id} id={card?.id} card={card}>
              <Card key={card.id} {...card} />
            </DraggableItem>
          ))}
        </div>
      </SortableContext>
    </Item>
  );
}

