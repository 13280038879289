import styled from 'styled-components';
import {Image as AntdImage} from 'antd';

export const ImageContainer = styled.div`
  position: relative;

  width: 150px;
  height: 150px;

  .image-order-indicator {
    display: ${(props) => (props.showOrderIndicator ? 'flex' : 'none')};

    font-size: 30px;
    font-weight: bold;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 999;
  }

  .ant-image {
    width: 150px !important;
    height: 150px !important;
  }

  .ant-image-mask {
    width: 150px !important;
    height: 150px !important;
  }

  .remove-button {
    display: none;
  }

  &:hover {
    .remove-button {
      display: flex;
    }
  }
`;

export const Image = styled(AntdImage)`
  width: 150px !important;
  height: 150px !important;

  object-fit: cover;
`;

export const RemoveImageIcon = styled.button`
  display: flex;

  position: absolute;
  z-index: 999;

  width: 20px;
  height: 20px;

  right: 0;

  outline: none;
  border-radius: 999px;

  align-items: center;
  justify-content: center;

  background-color: transparent;
  border-width: 0px;

  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: transparent;
  }
`;

export const HighlightContainer = styled.div`
  position: absolute;
  z-index: 999;
  width: 100%;
  bottom: 0px;

  text-align: center;
  padding-block: 4px;

  background-color: rgba(255, 255, 255, 0.7);

  > p {
    font-weight: 400;
    text-transform: uppercase;
    color: #333;
  }
`;

