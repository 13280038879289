/*eslint-disable*/
import React from 'react';
import {Button} from 'semantic-ui-react';

class ButtonDynamic extends React.Component {
  renderButtonPopup() {
    return this.renderButton({
      title: 'Você não possui permissão para acessar este recurso.',
      disabled: true,
    });
  }

  renderButton(outerProps = {}) {
    const {props} = this;
    const {children} = props;
    return (
      <Button {...props} {...outerProps}>
        {children}
      </Button>
    );
  }

  renderLoadingButton(outerProps = {}) {
    const {disabled, onClick, ...props} = this.props;

    return (
      <Button {...props} {...outerProps} disabled style={{color: this?.props?.loadColor || '#FFF', fontWeight: 'bold'}}>
        <div className="loading"></div>
        {this.props.loadtext}
      </Button>
    );
  }

  render() {
    const {allowed, isloading} = this.props;

    if (allowed !== undefined) {
    }

    if (isloading === 'true') {
      return this.renderLoadingButton();
    }

    return this.renderButton();
  }
}

export default ButtonDynamic;
