import React, { useState, useEffect } from 'react';
import { Modal } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import Lottie from 'lottie-react';
import houseJson from '~/assets/animations/house.json';

export const CircularProgressWithLabel = (props) => {
  return (
    <div style={{ position: 'relative', display: 'inline-flex', width: 200 }}>
      <Lottie animationData={houseJson} loop={true} />
    </div>
  );
};

export default function AuthLayout({ loadPage, text, icon, children }) {
  const [percent, setPercent] = useState(15);
  const [message, setMessage] = useState('Aguarde...');
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (text) {
      setMessage(text);
    }

    if (icon) {
      setImage(icon);
    }

    if (loadPage === undefined) {
      const timer = setInterval(() => {
        setPercent((prevProgress) =>
          prevProgress <= 100 ? prevProgress + 35 : prevProgress,
        );
      }, 25);
      return () => {
        clearInterval(timer);
      };
    } else {
      if (loadPage === false) {
        const timer = setInterval(() => {
          setPercent((prevProgress) =>
            prevProgress <= 100 ? prevProgress + 40 : prevProgress,
          );
        }, 50);
        return () => {
          clearInterval(timer);
        };
      } else {
        const timer = setInterval(() => {
          setPercent((prevProgress) =>
            prevProgress <= 100 ? prevProgress + 15 : prevProgress,
          );
        }, 75);
        return () => {
          clearInterval(timer);
        };
      }
    }
  }, [icon, loadPage, percent, text]);


  if (percent <= 100) {
    return (
      <>
        <Modal
          closeOnDimmerClick={false}
          size="small"
          dimmer="blurring"
          open={true}
          onClose={() => { }}>
          <Modal.Content image={!!image}>
            <div className="text-center my-4 px-2">
              {icon ? (
                <div className="my-4 loginMessage">
                  <Row>
                    <Col md="5"></Col>
                    <Col>
                      <img className="img-fluid" src={image} alt="..." />
                    </Col>
                    <Col md="5"></Col>
                  </Row>
                </div>
              ) : null}
              <div id="latitude-login-header" className="p-2 mt-2">
                {message}
              </div>
              <CircularProgressWithLabel size={100} value={percent} />
            </div>
          </Modal.Content>
        </Modal>
      </>
    );
  }

  return <>{children}</>;
}
