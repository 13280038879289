import React, { Fragment, useState } from 'react'
import {Modal, Image} from 'semantic-ui-react';
import {Select} from 'antd';
import {Row, Col} from 'reactstrap';

import newLead from '~/assets/img/NewLead.png';
import Button from '~/shared/Button';
import {Icon} from 'semantic-ui-react';
import { debounce } from '@material-ui/core'

function ModalOfertasAtivasDiponiveis({
  isSearching,
  ofertasAtivasDisponiveis,
  onOfertaAtivaSelecionada,
  onFiltraOfertasAtivasDisponiveis,
  open,
  onClose
}) {
  const [selectedOfertaAtiva, setSelectedOfertaAtiva] = useState(null);

  const handleOfertaAtivaSearch = debounce((value) => {
    onFiltraOfertasAtivasDisponiveis(value);
  }, 250);

  return (
    <Fragment>
      <Modal
        closeIcon
        size="large"
        onClose={() => onClose()}
        open={open}
      >
        <Modal.Content image>
          <div className="m-2 text-center">
            <Image size="small" src={newLead} wrapped />
          </div>
          <Col xs="10" className="mx-auto">
            <Row xs="8" className="justify-content-center">
              <Col xs="12" style={{ lineHeight: '2.5' }}>
              <Row>
                  <Col xs="12">
                    <div>
                      <h3 className="font-weight-bold text-center">
                        Escolha uma lista para ofertar.
                      </h3>
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-around">
                  <Col lg="6" className="mb-2">
                    <label>Lista</label>
                    <Select
                      className="w-100 md:w-100"
                      loading={isSearching}
                      showSearch={true}
                      filterOption={function (input, option) {
                        return option.children
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .indexOf(
                            input
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, ''),
                          ) >= 0
                      }}
                      allowClear={true}
                      placeholder="Selecione uma oferta ativa"
                      onSearch={(value) => handleOfertaAtivaSearch(value)}
                      onChange={(value) => setSelectedOfertaAtiva(ofertasAtivasDisponiveis.find((item) => item.id === value))}
                    >
                      {ofertasAtivasDisponiveis.map((item) => (
                        <Select.Option key={item.id} value={item.id} label={item.nome}>
                          {item.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Modal.Content>
        <Modal.Actions>
          <Row className="justify-content-center mb-3 mx-2">
            <Col xs="12" md="3">
              <Button
                className="my-2"
                size="tiny"
                icon
                labelPosition="left"
                fluid
                color="blue"
                disabled={!selectedOfertaAtiva}
                onClick={() => onOfertaAtivaSelecionada(selectedOfertaAtiva)}
              >
                <Icon name="check" />
                Avançar
              </Button>
            </Col>
          </Row>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
}

export default ModalOfertasAtivasDiponiveis;
