import React from 'react';

import DashboardComp from '~/views/private/Dashboard';
import EmpresaComp from '~/views/private/Cadastros/Empresa/Consulta';
import AtendimentosComp from '~/views/private/Cadastros/Atendimentos/Consulta';
import AtendimentosCompClone from '~/views/private/Cadastros/AtendimentosClone/Consulta';
import GrupoCorretoresComp from '~/views/private/Cadastros/GrupoCorretores/Consulta';
import TipoEstabelecimentoComp from '~/views/private/Cadastros/TipoEstabelecimento/Consulta';
import TipoCondominioComp from '~/views/private/Cadastros/TipoCondominio/Consulta';
import FinalidadesComp from '~/views/private/Cadastros/Finalidades/Consulta';
import TipoImovelComp from '~/views/private/Cadastros/TipoImovel/Consulta';
import TipoAtendimentoComp from '~/views/private/Cadastros/TipoAtendimento/Consulta';
import PerfilComp from '~/views/private/Cadastros/Perfil/Consulta';
import PerfilAcessoComp from '~/views/private/Cadastros/PerfilAcesso/Consulta';
import PerfilUsuarioComp from '~/views/private/Cadastros/PerfilUsuario';
import PerfilMenuComp from '~/views/private/Cadastros/PerfilMenu';
import TipoMidiaComp from '~/views/private/Cadastros/TipoMidia/Consulta';
import TipoLogradouroComp from '~/views/private/Cadastros/TipoLogradouro/Consulta';
import TipoHonorarioComp from '~/views/private/Cadastros/TipoHonorario/Consulta';
import TipoSoloComp from '~/views/private/Cadastros/TipoSolo/Consulta';
import BairrosComp from '~/views/private/Cadastros/Bairros/Consulta';
import ProfissoesComp from '~/views/private/Cadastros/Profissoes/Consulta';
import FormacoesComp from '~/views/private/Cadastros/Formacoes/Consulta';
import LogradouroComp from '~/views/private/Cadastros/Logradouro/Consulta';
import CondominioComp from '~/views/private/Cadastros/Condominio/Consulta';
import ImoveisComp from '~/views/private/Cadastros/Imoveis/Consulta';
import ProridadeComp from '~/views/private/PrioridadeDoDia';
import OfertaAtivaComp from '~/views/private/OfertaAtiva/Consulta';
import ConfiguracoesComp from '~/views/private/Configuracoes';
import RelatoriosComp from '~/views/private/Relatorios';
import PessoaComp from '~/views/private/Cadastros/Pessoa/Consulta';
import MasterComp from '~/views/private/Cadastros/Master/Consulta';
import CorretorComp from '~/views/private/Cadastros/Corretor/Consulta';
import PlantoesComp from '~/views/private/Cadastros/Plantao/Consulta';
import EscalasComp from '~/views/private/Escalas';
import Importaco from '~/views/private/Cadastros/Empresa/Integracao';
import MenuComp from '~/views/private/Cadastros/Menu/Consulta';
import AcoesComp from '~/views/private/Cadastros/Acoes/Consulta';
import ComidaFavoritaComp from '~/views/private/Cadastros/ComidaFavorita/Consulta';
import ListagemUrlComp from '~/views/private/ListagemUrl';
import EntreAgenciasComp from '~/views/private/Cadastros/EntreAgencias';
import SiteComp from '~/views/private/Site';
import FacebookComp from '~/views/private/Configuracoes/components/distribuicao/components/facebookLoginRedirect';
import ImoveisExportComp from '~/views/private/ImovelExport';
import DistribuicaoComp from '~/views/private/Configuracoes/components/distribuicao/distribuicao';

const Dashboard = () => <DashboardComp />;
const Perfil = () => <PerfilComp />;
const PerfilAcesso = () => <PerfilAcessoComp />;
const Empresa = () => <EmpresaComp />;
const ImportacaoEmpresa = () => <Importaco />;
const Pessoa = () => <PessoaComp />;
const Master = () => <MasterComp />;
const Corretor = () => <CorretorComp />;
const Atendimentos = () => <AtendimentosComp />;
const AtendimentosClone = () => <AtendimentosCompClone />;
const GrupoCorretores = () => <GrupoCorretoresComp />;
const TipoEstabelecimento = () => <TipoEstabelecimentoComp />;
const TipoCondominio = () => <TipoCondominioComp />;
const Finalidades = () => <FinalidadesComp />;
const TipoImovel = () => <TipoImovelComp />;
const PerfilUsuario = () => <PerfilUsuarioComp />;
const PerfilMenu = () => <PerfilMenuComp />;
const TipoAtendimento = () => <TipoAtendimentoComp />;
const TipoMidia = () => <TipoMidiaComp />;
const TipoLogradouro = () => <TipoLogradouroComp />;
const TipoHonorario = () => <TipoHonorarioComp />;
const TipoSolo = () => <TipoSoloComp />;
const Bairros = () => <BairrosComp />;
const Profissoes = () => <ProfissoesComp />;
const Formacoes = () => <FormacoesComp />;
const Logradouro = () => <LogradouroComp />;
const Condominio = () => <CondominioComp />;
const Imoveis = () => <ImoveisComp />;
const Prioridade = () => <ProridadeComp />;
const OfertaAtiva = () => <OfertaAtivaComp />;
const Configuracoes = () => <ConfiguracoesComp />;
const Relatorios = () => <RelatoriosComp />;
const Plantoes = () => <PlantoesComp />;
const Escalas = () => <EscalasComp />;
const Menu = () => <MenuComp />;
const Acoes = () => <AcoesComp />;
const ComidaFavorita = () => <ComidaFavoritaComp />;
const ListagemUrl = () => <ListagemUrlComp />;
const EntreAgencias = () => <EntreAgenciasComp />;
const Site = () => <SiteComp />;
const Facebook = () => <FacebookComp />;
const ImovelExport = () => <ImoveisExportComp />;
const Distribuicao = () => <DistribuicaoComp />;

export {
  Dashboard,
  Perfil,
  PerfilAcesso,
  Empresa,
  ImportacaoEmpresa,
  Pessoa,
  Master,
  Corretor,
  Atendimentos,
  AtendimentosClone,
  GrupoCorretores,
  TipoEstabelecimento,
  TipoCondominio,
  Finalidades,
  TipoImovel,
  TipoAtendimento,
  PerfilUsuario,
  PerfilMenu,
  TipoMidia,
  TipoLogradouro,
  TipoHonorario,
  TipoSolo,
  Bairros,
  Profissoes,
  Formacoes,
  Logradouro,
  Condominio,
  Imoveis,
  Prioridade,
  OfertaAtiva,
  Configuracoes,
  Relatorios,
  Plantoes,
  Escalas,
  Menu,
  Acoes,
  ComidaFavorita,
  ListagemUrl,
  EntreAgencias,
  Site,
  Facebook,
  ImovelExport,
  Distribuicao
};
