import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useLeadOfertaCorretor(corretorId) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const filterCorretorId = corretorId ? corretorId : null;

    if (!filterCorretorId)
      return;

    let filter = `?corretorId=${filterCorretorId}`;

    try {
      await api.get(`${url.OFERTA_ATIVA.PROPOSTA_ATIVA_FILA_CORRETOR()}${filter}`)
        .then(res => {
          setData(res?.data);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [corretorId]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
