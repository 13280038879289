import React from 'react';
import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Row, Col } from 'reactstrap';

import CardUpload from '~/components/CardUpload';

const antButton = <Button icon={<UploadOutlined />}>Buscar imagens</Button>;

function Imagens({
  modo,
  imovelId,
  listImages,
  initialValuesImages,
  generateInitialValueImages,
  setInitialValuesImages,
  identificador,
  keepFilter,
}) {
  function voltar() {
    keepFilter();
    window.close();
  }

  return (
    <div className="px-4 mb-4">
      <Row>
        <Col xs="12">
          <CardUpload
            modo={modo}
            multiFile
            antButton={antButton}
            dataImages={listImages}
            apiURL="/imovel/imagens"
            id={imovelId}
            endPoint="imovel"
            nameIdInFormDataPost="imovelId"
            nameIdInFormDataPut="ImovelImagemId"
            voltar={() => voltar()}
            showDescricao={true}
            showDestaque={true}
            descriptionDestaque="Foto de Capa"
            showSuperDestaque={false}
            showPublicavel={true}
            initialValuesImages={initialValuesImages}
            generateInitialValueImages={(value) =>
              generateInitialValueImages(value)
            }
            setInitialValuesImages={(value) => setInitialValuesImages(value)}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Imagens;
