/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Form, Input } from 'antd';
import { Icon } from 'semantic-ui-react';
import { mask, unMask } from 'remask';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import CircularProgress from '~/components/HomeLoading';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import { checkDocumento } from '~/shared/checkDocumento';

import url from '~/infra/urls';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import pattern from '~/infra/resources/pattern/mask';

import UploadImage from '~/components/UploadImage';


function EditUserSimple(props) {
  const [loadPage, setloadPage] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [fileLogo, setFileLogo] = useState(null);
  const [alterLogo, setAlterLogo] = useState(false);

  const [form] = Form.useForm();

  const userId = localStorage.getItem('icaseUsuarioId');

  async function getUserData() {
    if (userId) {
      setloadPage(true);

      return await api.get(url.USUARIO.CONSULTA_BY_ID(userId))
        .then(async res => {
          return res.data;
        })
        .catch(err => {
          setloadPage(false);
          const { error } = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
    }
  }


  useEffect(() => {
    async function getData() {
      const userResult = await getUserData();

      setFileLogo({ url: userResult?.imagemUrl });
      setInitialValues({
        nome: userResult?.nomeCompleto || '',
        roleId: userResult?.roles[0]?.roleId,
        email: userResult?.email || '',
        creci: userResult?.creci || '',
        cpf: mask(userResult?.cpf || '', [pattern.cpf]),
        celular: mask(userResult?.celular || '', [pattern.celular]),
        instagram: userResult?.instagram,
      });

      await new Promise(resolve => setTimeout(resolve, 1000));
      setloadPage(false);
    }

    if (props.modalOpen)
      getData();

  }, [props.modalOpen]);

  function onSave() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        const payload = {
          id: userId,
          nome: dados?.nome,
          email: dados?.email,
          cpf: dados.cpf ? dados.cpf.replace(/\D/g, '') : dados?.cpf,
          celular: dados.celular,
          creci: dados?.creci,
          instagram: dados?.instagram,
          imagem: alterLogo ? fileLogo?.url : null,
        };

        await api
          .put(url.CORRETOR.ATUALIZAR_SIMPLE(), payload)
          .then((res) => {
            setIsLoading(false);
            let currentStorageUser = JSON.parse(localStorage.getItem('icaseUser'));
            currentStorageUser.userPictureUrl = fileLogo?.url;

            localStorage.setItem('icaseUserCpf', payload?.cpf);
            localStorage.setItem('icaseUser', JSON.stringify(currentStorageUser));
            props.voltar();
            Notify('success', pt.comum.atencao, 'Usuário atualizado com sucesso!');
          })
          .catch((err) => {
            setIsLoading(false);
            const { error } = err.response.data;
            if (error) {
              for (const item in error) {
                Notify('error', pt.comum.atencao, error[item]);
              }
            }
          });

      })
      .catch((errorInfo) => {
        setIsLoading(false);
        window.scrollTo(1, 1);
      });
  }

  function handleMask(e, type) {
    let value = e.currentTarget.value;
    value = unMask(value);
    value = mask(value, [type]);

    if (type === pattern.cpf) form.setFieldsValue({ cpf: value });
    if (type === pattern.celular) form.setFieldsValue({ celular: value });
  }

  return (
    <Fragment>
      {loadPage && <div>
        <CircularProgress />
        <SkeletonTableBasic />
      </div>}
      {!loadPage && <Row>
        <Col xs="12">
          <Card className="p-2">
            <CardHeader>
              <h5 className="title">
                Atualizar usuário
              </h5>
              <p className="category">Manutenção de usuário</p>
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs="12" md="3">
                  <UploadImage
                    fileLogo={fileLogo}
                    setFileLogo={setFileLogo}
                    setAlterLogo={setAlterLogo}
                    maxImageSize={5}
                    minWidth={354}
                    minHeight={472}
                  />
                </Col>
                <Col xs="12" md="9">
                  <Form
                    form={form}
                    initialValues={initialValues}
                    scrollToFirstError>
                    <Row>
                      <Col xs="12" md="6">
                        <p className="heading-small text-muted mb-2 ml-2">
                          <small>Nome</small>
                        </p>
                        <Form.Item
                          className="ml-2"
                          name="nome"
                          rules={[
                            {
                              required: true,
                              message: 'Favor informar o nome!',
                            },
                          ]}>
                          <Input placeholder=" Nome" />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="6">
                        <p className="heading-small text-muted mb-2">
                          <small>CRECI</small>
                        </p>
                        <Form.Item
                          className="mr-2"
                          name="creci">
                          <Input placeholder=" CRECI" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6">
                        <p className="heading-small text-muted mb-2 ml-2">
                          <small>Email</small>
                        </p>
                        <Form.Item
                          className="ml-2"
                          name="email"
                          rules={[
                            {
                              required: true,
                              type: 'email',
                              message:
                                'Favor inserir um e-mail válido',
                            },
                          ]}>
                          <Input placeholder=" Email" />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="6">
                        <p className="heading-small text-muted mb-2">
                          <small>Instagram</small>
                        </p>
                        <Form.Item
                          className="mr-2"
                          name="instagram">
                          <Input placeholder=" Instagram" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6">
                        <p className="heading-small text-muted mb-2 ml-2">
                          <small>Celular</small>
                        </p>
                        <Form.Item
                          className="ml-2"
                          name="celular"
                          rules={[
                            {
                              required: true,
                              message: 'Favor informar o celular!',
                            },
                          ]}>
                          <Input placeholder=" Celular" onChange={(e) => handleMask(e, pattern.celular)} />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="6">
                        <p className="heading-small text-muted mb-2">
                          <small>CPF</small>
                        </p>
                        <Form.Item
                          className="mr-2"
                          name="cpf"
                          rules={[
                            {
                              required: true,
                              message: 'Favor informe o CPF',
                            },
                            // eslint-disable-next-line no-empty-pattern
                            ({ }) => ({
                              validator(rule, value) {
                                if (value) {
                                  const unMaskedValue =
                                    unMask(value);
                                  const isDocumento =
                                    checkDocumento(
                                      'cpf',
                                      unMaskedValue,
                                    );
                                  if (isDocumento === true) {
                                    return Promise.reject(
                                      'Número de CPF inválido',
                                    );
                                  }
                                }

                                return Promise.resolve();
                              },
                            }),
                          ]}>
                          <Input
                            placeholder=" Número do CPF"
                            onKeyUp={(e) =>
                              handleMask(e, pattern.cpf)
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs="12" md="3">
                  <Button
                    onClick={onSave}
                    icon
                    labelPosition="left"
                    size="medium"
                    fluid
                    className="mt-3"
                    color="violet"
                    isloading={loading.toString()}
                    loadtext={'Salvando...'}>
                    <Icon name="check" />
                    Salvar
                  </Button>
                </Col>
                <Col xs="12" md="3">
                  <Button
                    icon
                    labelPosition="left"
                    size="medium"
                    fluid
                    className="mt-3"
                    onClick={() => props.voltar()}>
                    <Icon name="undo" />
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>}
    </Fragment>
  );
}

export default EditUserSimple;
