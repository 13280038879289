import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import Lottie from 'lottie-react';
import houseJson from '~/assets/animations/house.json';

function HomeLoading() {
  return (
    <Fragment>
      <Row>
        <Col xs="12">
          <div className="p-4 m-4 text-center">
            <Lottie style={{width: 200}} animationData={houseJson} loop={true} />
            <br />
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

export default HomeLoading;
