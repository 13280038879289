import React from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Dropdown, Menu } from 'antd';
import {
  MoreOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import formatTelefone from '~/shared/formatTelefone';

import history from '~/services/history';

function CardUser({
  type = 'usuário',
  user,
  url,
  identificador,
  actions,
  showEdit,
  showInactivate,
  functionInactivate,
  showResetPassword,
  functionResetPassword,
}) {
  const grupoCorretores = user?.grupoCorretor
    ?.map((item) => item.grupo)
    ?.join(' / ');
  const excluido = user?.excluido || false;

  const editar = !!actions.find(
    (action) => action.id === 'f97bf5a0-f8f4-401e-b5c0-3d9614fa927a',
  );
  const inativar = !!actions.find(
    (action) => action.id === '5aeb97ac-ca9b-4c05-977d-1a3106eedd59',
  );

  const navToUrl = () => {
    if (url) {
      history.push({
        pathname: url,
        state: { user, identificador },
      });
    }
  };

  const menuItems = [];

  if (showEdit && editar) {
    menuItems.push({
      label: 'Editar',
      key: 0,
      icon: <EditOutlined />,
      onClick: navToUrl ? () => navToUrl() : null,
    });
  }

  if (showInactivate && inativar) {
    menuItems.push({
      label:
        excluido === true ? (
          <span style={{ color: 'green' }}>Ativar {type}</span>
        ) : (
          <span style={{ color: 'red' }}>Inativar {type}</span>
        ),
      key: 1,
      icon:
        excluido === true ? (
          <CheckCircleOutlined style={{ color: 'green' }} />
        ) : (
          <CloseCircleOutlined style={{ color: 'red' }} />
        ),
      onClick: functionInactivate ? () => functionInactivate() : null,
    });
  }

  if (showResetPassword) {
    menuItems.push({
      label: 'Resetar senha',
      key: 2,
      icon: <SyncOutlined />,
      onClick: functionResetPassword
        ? () => functionResetPassword(user.email)
        : null,
    });
  }

  const overlay = <Menu items={menuItems} />;
  const userImage = user?.imagemUrl
    ? user.imagemUrl.replace(/\s+/g, '%20')
    : 'https://locare.s3.sa-east-1.amazonaws.com/CRM/commom/gestor.png?size=small';


  return (
    <Card className="card-user-container">
      <Row className="card-user-content no-gutters">
        <Col xs="12" lg="6" xl="4" className="card-user-container-image">
          <div
            className="card-user-image"
            style={{
              backgroundImage: `url(${userImage})`,
            }}>
            .
          </div>
        </Col>
        <Col
          xs="12"
          lg="6"
          xl="8"
          className="d-flex flex-column justify-content-between h-100">
          <Row className="no-gutters">
            <Col xs="12" className="h-100">
              <CardBody className="pt-1">
                <CardTitle>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center',
                    }}>
                    <span
                      className="text-wrap tittle-user"
                      style={{ fontSize: '1.125em' }}>
                      {user?.nomeCompleto}
                    </span>

                    {type === 'usuário' && (
                      <Dropdown.Button
                        className='fixWidth'
                        placement="topLeft"
                        icon={<MoreOutlined size={24} />}
                        buttonsRender={([leftButton, rightButton]) => [
                          React.cloneElement(leftButton),
                          React.cloneElement(rightButton, {
                            type: 'primary',
                            style: { borderRadius: '50%' },
                          }),
                        ]}
                        overlay={overlay}
                        trigger={['click']}
                      />
                    )}
                  </div>
                </CardTitle>
                <CardText>
                  <span className="text-wrap">{user?.userName}</span>
                </CardText>
                <CardText>
                  <span className="text-wrap">{user?.celular ? formatTelefone(user?.celular || '') : ''}</span>
                </CardText>
                <CardText>
                  <span className="text-wrap">
                    <b>CRECI: </b>
                    {user?.creci ? user.creci : 'CRECI não informado'}
                  </span>
                </CardText>
                {/* <CardText><span className="text-wrap"><b>Filial: </b>{user?.filial}</span></CardText> */}
                <CardText>
                  <span className="text-wrap">
                    <b>Equipe: </b>
                    {grupoCorretores
                      ? grupoCorretores
                      : 'Sem equipe selecionada'}
                  </span>
                </CardText>
              </CardBody>
            </Col>
          </Row>
          {type !== 'usuário' && (
            <Row className="no-gutters">
              <Col xs="12" className="h-100">
                <CardBody className="d-flex align-items-center">
                  {showEdit && (
                    <div
                      className="d-flex align-items-center mr-2 violet"
                      onClick={() => navToUrl()}
                      style={{ cursor: 'pointer' }}>
                      <i
                        className="ri-pencil-fill text-dark"
                        style={{
                          fontSize: '18px',
                          fontWeight: '400',
                          marginRight: 5,
                        }}
                      />
                      <span style={{ fontSize: '1.125em' }}></span>
                    </div>
                  )}
                  {showResetPassword && (
                    <div
                      className="d-flex align-items-center mr-2 violet"
                      onClick={() => functionResetPassword(user.email)}
                      style={{ cursor: 'pointer' }}>
                      <i
                        className="ri-refresh-line text-dark"
                        style={{
                          fontSize: '18px',
                          fontWeight: '400',
                          marginRight: 5,
                        }}></i>
                      <span style={{ fontSize: '1.125em' }}>Resetar senha</span>
                    </div>
                  )}
                  {showInactivate && (
                    <div
                      className={`d-flex align-items-center mr-2 ${excluido === true ? 'green' : 'red'
                        }`}
                      onClick={() => functionInactivate()}
                      style={{ cursor: 'pointer' }}>
                      <i
                        className={`${excluido === true
                            ? 'ri-checkbox-circle-line'
                            : 'ri-close-circle-line'
                          }`}
                        style={{
                          fontSize: '18px',
                          fontWeight: '400',
                          marginRight: 5,
                        }}
                      />
                      <span style={{ fontSize: '1.125em' }}>
                        {excluido === true ? 'Ativar' : 'Inativar'} {type}
                      </span>
                    </div>
                  )}
                </CardBody>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default CardUser;
