import { useCallback, useState } from 'react'
import api from '~/services/api'
import url from '~/infra/urls'

export default function useOfertasAtivasDisponiveis() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async (filtroOfertaAtivaNome) => {
    setIsLoading(true);
    let filter = []

    if (filtroOfertaAtivaNome) {
      filter.push(['ofertaAtivaNome', filtroOfertaAtivaNome])
    }

    const urlParams = `?${filter.map(f => f.join('=')).join('&')}`

    try {
      return await api.get(`${url.OFERTA_ATIVA.PROPOSTAS_ATIVAS_DISPONIVEIS()}${urlParams}`)
        .then(res => {
          setData(res?.data?.data || []);
          setQuantidade(res?.data?.total || 0);
          setIsLoading(false);

          return {
            data: res?.data?.data || [],
            quantidade: res?.data?.total || 0,
          }
        });
    } catch (e) {
      setData([]);
      setQuantidade(0);

      return {
        data: [],
        quantidade: 0,
      }
    } finally {
      setIsLoading(false);
    }
  }, [setData, setQuantidade, setIsLoading]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
    setFiltroOfertaAtivaNome: (value) => {
      void onLoad(value)
    }
  };
}
