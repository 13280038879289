import React, { Fragment, useCallback, useEffect, useState } from 'react'
import Button from '~/shared/Button';
import { Icon } from 'semantic-ui-react';
import { Col } from 'reactstrap';
import pt from '~/infra/resources/strings/pt';
import url from '~/infra/urls';
import api from '~/services/api';
import Notify from '~/shared/Notify';
import 'moment/locale/pt-br';
import moment from 'moment';
import ModalOferta from '~/components/ModalOfertaAtiva';
import ModalGenerico from '~/components/ModalGenerico';
import AtendimentoManut from '~/views/private/Cadastros/Atendimentos/Manutencao';
import useOfertasAtivasDisponiveis from '~/actions/OfertaAtiva/useOfertasAtivasDisponiveis'
import ModalOfertasAtivasDisponiveis from '~/components/ModalOfertasAtivasDisponiveis'
import useLocalStorage from '~/hooks/localstorage/useLocalStorage'

moment.locale('pt-BR');

const today = new Date();

function OfertaAtivaCorretor({grupoCorretoresId, onReponse}) {
  const [clienteOferta, setClienteOferta] = useState({});
  const [ofertaOpen, setOfertaOpen] = useState(false);
  const [pessoaId] = useLocalStorage('UsuarioId', null)
  const [novoAtendimentoId, setNovoAtendimentoId] = useState();
  const [loadingOfertaAtiva, setLoadingOfertaAtiva] = useState(false);
  const {
    isLoading,
    data: ofertasAtivasDisponiveis,
    onLoad: onLoadOfertasAtivasDisponiveis,
    setFiltroOfertaAtivaNome,
  } = useOfertasAtivasDisponiveis()

  const [listaOfertaAtivaSelecionada, setListaOfertaAtivaSelecionada] = useLocalStorage('OfertaAtivaSelecionada', null)

  const carregaOfertaAtiva = useCallback(async () => {
    let filter = `?corretorId=${pessoaId}&propostaAtivaId=${listaOfertaAtivaSelecionada.id}`;

    if (grupoCorretoresId) {
      if (filter.length > 0) filter += `&grupoCorretoresId=${grupoCorretoresId}`;
    }

    setLoadingOfertaAtiva(true);
    await api
      .get(`${url.OFERTA_ATIVA.CONSULTAR()}${filter}`)
      .then((res) => {
        if (res.status === 204) {
          Notify('info', '', 'A lista de ofertas ativas que você selecionou não está mais disponível. Por favor, selecione outra lista de ofertas ativas clicando no botão.');
          setListaOfertaAtivaSelecionada(null);
          setClienteOferta({});
          setLoadingOfertaAtiva(false);
          setOfertaOpen(false);
          return;
        }

        setLoadingOfertaAtiva(false);
        setClienteOferta(res.data);
        setOfertaOpen(true);
      })
      .catch((e) => {
        console.error(e?.response?.data)
        Notify('error', '', 'Ooops, algo deu errado. Por favor, tente novamente mais tarde.');
        setLoadingOfertaAtiva(false);
        setOfertaOpen(false);
        setClienteOferta({});
      });
  }, [grupoCorretoresId, pessoaId, listaOfertaAtivaSelecionada, setLoadingOfertaAtiva, setListaOfertaAtivaSelecionada])

  useEffect(() => {
    if (!isLoading && listaOfertaAtivaSelecionada && ofertaOpen) {
      void carregaOfertaAtiva();
    }
  }, [isLoading, ofertaOpen, listaOfertaAtivaSelecionada, carregaOfertaAtiva])

  const handleGetOfertaAitvaClick = async () => {
    if (!listaOfertaAtivaSelecionada) {
      await onLoadOfertasAtivasDisponiveis()
      setOfertaOpen(true);
      return;
    }
    void carregaOfertaAtiva();
  }

  const responseOferta = async (cliente, status) => {
    void onReponse();

    setOfertaOpen(false);

    if (status !== 2) {
      const payload = {
        filaAtendimentoId: cliente.id,
        status: status,
      };

      await api
        .put(url.OFERTA_ATIVA.EDITAR(), payload)
        .then((res) => {
          setOfertaOpen(false);
          setClienteOferta({});
          Notify('success', '', 'Ação realizada com sucesso!');
        })
        .catch((err) => {
          if (err.response.status === 422) {
            Notify('info', '', err.responseText);
            setOfertaOpen(false);
            setClienteOferta({});
            return;
          }
          setOfertaOpen(false);
          Notify(
            'error',
            '',
            'Ooops, favor realizar uma nova tentativa mais tarde!',
          );
        });
    } else if (status === 2) {
      const payload = {
        corretorId: pessoaId,
        tipoMidia: 5, // OFERTA ATIVA
        situacao: 1, // ATIVO,
        nomeCliente: cliente.nomeCompleto,
        foneCliente: cliente.telefone1,
        descricaoUltimoAtendimento: 'Atendimento gerado via oferta ativa',
        dataUltimoAtendimento: moment(today, 'YYYY-MM-DD'),
        status: 2, // EM NEGOCIAÇÃO
      };

      await api
        .post(url.ATENDIMENTO.CADASTRO_BY_OFERTA_ATIVA(), payload)
        .then(async (newAtendimento) => {
          const payload = {
            filaAtendimentoId: cliente.id,
            status: 2,
          };

          await api
            .put(url.OFERTA_ATIVA.EDITAR(), payload)
            .then(() => {
              Notify('success', '', pt.geral.cadastro_com_sucesso);
              setNovoAtendimentoId(newAtendimento?.data?.data?.id);
            })
            .catch(() => {
              Notify(
                'error',
                '',
                'Ooops, favor realizar uma nova tentativa mais tarde!',
              );
            });
        })
        .catch((err) => {
          const { error } = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
    }
  }

  return (
    <Fragment>
      <Col xs="12" md="3">
        <div className="my-2">
          <Button
            icon
            labelPosition="left"
            size="tiny"
            fluid
            color="green"
            onClick={() => handleGetOfertaAitvaClick(grupoCorretoresId)}>
            <Icon name="check square" />
            Oferta ativa
          </Button>
        </div>
      </Col>
      {!listaOfertaAtivaSelecionada && (
        <ModalOfertasAtivasDisponiveis
          open={ofertaOpen}
          ofertasAtivasDisponiveis={ofertasAtivasDisponiveis}
          onOfertaAtivaSelecionada={(ofertaAtiva) => {
            setListaOfertaAtivaSelecionada(ofertaAtiva);
          }}
          isSearching={isLoading}
          onFiltraOfertasAtivasDisponiveis={(ofertaAtivaNome) => {
            setFiltroOfertaAtivaNome(ofertaAtivaNome);
          }}
          onClose={() => {
            setOfertaOpen(false)
            setClienteOferta({})
          }}
        />
      )}
      {listaOfertaAtivaSelecionada && (
        <ModalOferta
          open={ofertaOpen}
          loading={loadingOfertaAtiva}
          response={(cliente, status) => responseOferta(cliente, status)}
          cliente={clienteOferta}
          ofertaAtivaSelecionada={listaOfertaAtivaSelecionada}
          onTrocaLista={() => setListaOfertaAtivaSelecionada(null)}
        />
      )}
      <ModalGenerico
        open={novoAtendimentoId}
        close={() => setNovoAtendimentoId(null)}
        size="large">
        <AtendimentoManut
          match={{ params: { modo: 'editar', tab: 2 } }}
          location={{ state: { id: novoAtendimentoId } }}
          onClose={() => setNovoAtendimentoId(null)}
        />
      </ModalGenerico>
    </Fragment>
  );
}

export default OfertaAtivaCorretor;
