import {useState} from 'react';
import {
  defaultDropAnimationSideEffects,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {arrayMove} from '@dnd-kit/sortable';

export default function useLatitudeBoard(initialLanes) {
  const [lanes, setLanes] = useState(initialLanes);
  const [clonedLanes, setClonedLanes] = useState(null);

  const [activeCard, setActiveCard] = useState(null);
  const [activeColumn] = useState(null);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  /**
   * Drag and drop handlers
   */

  function dragStartHandler(event) {
    const {active} = event;

    const type = active.data.current.type;

    if (type && type === 'Card') {
      const currentCard = active.data?.current?.props;
      setActiveCard(currentCard);
      setClonedLanes(lanes);
    }
  }

  function dragOverHandler(event) {
    const {active, over} = event;
    const overId = over?.id;

    if (!overId || !active?.id) return;

    const activeColumnId = active.data?.current?.sortable?.containerId;
    const overColumnId = over.data?.current?.sortable?.containerId;

    if (!activeColumnId || !overColumnId) return;

    // Scenario: Dragging card to another column
    if (activeColumnId !== overColumnId && overColumnId !== 'Sortable') {
      console.log('Getting here...', {active, over});
      // Active item is removed from its parent lane.
      // Over column receives the active item.

      setLanes((currentLanes) => {
        const activeItems =
          currentLanes.find((lane) => lane.id === activeColumnId)?.cards || [];

        const overItems =
          currentLanes.find((lane) => lane.id === overColumnId)?.cards || [];

        const activeIndex = activeItems.findIndex(
          (item) => item.id === active?.id,
        );

        const overIndex = overItems.findIndex((item) => item.id === over?.id);

        let newIndex;

        if (overIndex === -1) {
          newIndex = overItems.length + 1;
        } else {
          // Get over card position
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
              over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;

          newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length;
        }

        return currentLanes.map((lane) => {
          if (lane?.id === activeColumnId) {
            return {
              ...lane,
              cards: lane?.cards.filter((card) => card.id !== active.id),
            };
          }

          if (lane.id === overColumnId) {
            return {
              ...lane,
              cards: [
                ...overItems.slice(0, newIndex),
                activeItems[activeIndex],
                ...overItems.slice(newIndex),
              ],
            };
          }

          return lane;
        });
      });
    }
  }

  function dragEndHandler(event) {
    const {active, over} = event;
    const overId = over?.id;

    if (!overId) return;

    const activeColumnId = active?.data?.current?.sortable?.containerId;
    const overColumnId = over?.data?.current?.sortable?.containerId;

    if (!activeColumnId || !overColumnId) return;

    if (activeColumnId === overColumnId) {
      setLanes((currentLanes) => {
        const activeItems =
          currentLanes.find((lane) => lane.id === activeColumnId)?.cards || [];
        const overItems =
          currentLanes.find((lane) => lane.id === overColumnId)?.cards || [];

        const activeIndex = activeItems.findIndex(
          (item) => item.id === active?.id,
        );
        const overIndex = overItems.findIndex((item) => item.id === overId);

        return currentLanes.map((lane) => {
          if (lane.id === activeColumnId) {
            return {
              ...lane,
              cards: arrayMove(activeItems, activeIndex, overIndex),
            };
          }

          return lane;
        });
      });
    }
  }
  function onDragCancel() {
    if (clonedLanes) {
      setLanes(clonedLanes);
      setClonedLanes(null);
    }
  }

  /**
   * Animation defs
   */
  const adjustScale = false;
  const dropAnimation = {
    duration: 300,
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: 0.5,
        },
      },
    }),
  };

  function getLaneColor(index) {
    const colors = [
      '#bbdefb',
      '#2196f3',
      '#ffe082',
      '#ffca28',
      '#ff6f00',
      '#dd2c00',
      '#bdbdbd',
    ];

    return colors[index % colors.length] || colors[0];
  }

  return {
    lanes,
    activeCard,
    activeColumn,
    adjustScale,
    dropAnimation,
    dragStartHandler,
    dragEndHandler,
    dragOverHandler,
    onDragCancel,
    getLaneColor,
    sensors,
  };
}

