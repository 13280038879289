import React from 'react';

import useLatitudeBoard from './hooks/use-latitude-board';

import {
  closestCenter,
  DndContext,
  DragOverlay,
  MeasuringStrategy,
} from '@dnd-kit/core';
import {Container, ColumnWrapper} from './styles';

import DroppableItem from './components/DroppableItem';
import {createPortal} from 'react-dom';

export default function LatitudeBoard({data, components}) {
  const Header = components?.Header;
  const Card = components?.Card;

  const {
    lanes,
    activeCard,
    adjustScale,
    dropAnimation,
    getLaneColor,
    dragStartHandler,
    dragEndHandler,
    dragOverHandler,
    onDragCancel,
    sensors,
  } = useLatitudeBoard(data?.lanes);

  const renderColumns = lanes?.map((lane, index) => (
    <DroppableItem
      key={lane?.id}
      id={lane?.id}
      laneColor={getLaneColor(index)}
      title={lane?.title}
      cards={lane?.cards}
      Header={Header}
      Card={Card}
    />
  ));

  return (
    <DndContext
      sensors={sensors}
      onDragStart={dragStartHandler}
      onDragEnd={dragEndHandler}
      onDragOver={dragOverHandler}
      onDragCancel={onDragCancel}
      collisionDetection={closestCenter}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.BeforeDragging,
        },
      }}>
      <Container>
        <ColumnWrapper>{renderColumns}</ColumnWrapper>
      </Container>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {activeCard && <Card {...activeCard} />}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  );
}

